import React, { useState } from 'react';
import './APersonalesNaturalForm.scss';
import { useHistory, useParams } from 'react-router-dom';
import { ModalCedula } from '../../ModalCedulaNatural/ModalCedula';
import { ModalUsPerson } from '../../ModalUsPerson/ModalUsPerson';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../../Input/Input';
import { RepresentanteLegalForm } from '../../RepresentanteLegalForm/RepresentanteLegalForm';
import { useDispatch, useSelector } from 'react-redux';
import { paises } from '../../../utils/countries';
import { nacionalidad } from '../../../utils/nacionalidades';

import {
    addAnotherNacionalidad,
    addRepresentanteLegal,
    changeDireccionDomicilioFiscal,
    changeErrDireccionDomicilioFiscal,
    changeNacionalidadDireccionError,
    changeNacionalidadDireccionField,
    changeNacionalidadesError,
    changeNacionalidadesField,
    changePersonalError,
    changePersonalField,
    changeRepresentanteLegalError,
    removeCedulaDorso,
    removeCedulaFrente,
    removeNacionalidad,
} from '../../../redux/reducers/naturalForm';
import iconClose from '../../../assets/svg/icon-close.svg';

const popoverPolitica =
    '“Son los chilenos o extranjeros que desempeñen o hayan desempeñado funciones públicas destacadas en Chile o en el extranjero, hasta un año de finalizado el ejercicio de las mismas. Se incluyen en esta categoría los jefes de estado o de un gobierno, políticos de alta jerarquía (entre ellos, a los miembros de mesas directivas de partidos políticos), funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales; en todos estos casos comprende también a sus cónyuges, sus parientes hasta el segundo grado de consanguinidad (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)) y a las personas naturales con las que hayan celebrado un pacto de actuación conjunta mediante el cual tengan poder de voto suficiente , para influir en sociedades constituidas en Chile.”';

export const APersonalesNaturalForm = ({ setFormStep, data, setData }: any) => {
    const [showModalUsPerson, setShowModalUsPerson] = useState(false);
    const [showModalCedula, setShowModalCedula] = useState(false);
    const history = useHistory();
    const { type }: any = useParams();
    const aPersonalesState = useSelector((state: any) => state.naturalForm.aPersonales);
    const errPersonalState = useSelector((state: any) => state.naturalForm.errAPersonales);
    const masNacionalidades = useSelector((state: any) => state.naturalForm.masNacionalidades);
    const errMasNacionalidades = useSelector((state: any) => state.naturalForm.errMasNacionalidades);
    const representantesLegales = useSelector((state: any) => state.naturalForm.representantesLegales);
    const direccionDomicilioFiscal = useSelector((state: any) => state.naturalForm.direccionDomicilioFiscal);
    const errDireccionDomicilioFiscal = useSelector((state: any) => state.naturalForm.errDireccionDomicilioFiscal);
    const [showPopoverPolitica, setshowPopoverPolitica] = useState(false);
    const dispatch = useDispatch();
    const validateEmail = (email: any) => {
        var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    const validateAPersonalesData = () => {
        let isValid = true;
        Object.entries(aPersonalesState).map((item: any) => {
            if (item[0] !== 'dpto' && item[0] !== 'cedulaFrente' && item[0] !== 'cedulaDorso') {
                dispatch(changePersonalError({ field: item[0], content: item[1] === '' }));
                if (item[1] === '') isValid = false;
            }
            if (item[0] === 'email') {
                if (!validateEmail(item[1])) {
                    dispatch(changePersonalError({ field: item[0], content: true }));
                    isValid = false;
                } else {
                    dispatch(changePersonalError({ field: item[0], content: false }));
                }
            }
            return null;
        });
        if (aPersonalesState.otraNacionalidad) {
            Object.entries(masNacionalidades).map((item: any, i: any) => {
                dispatch(changeNacionalidadesError({ index: i, content: item[1].nacionalidad === '' }));
                dispatch(changeNacionalidadDireccionError({ index: i, content: item[1].direccion === '' }));
                if (item[1].nacionalidad === '' || item[1].direccion === '') isValid = false;
                return null;
            });
        }
        if (aPersonalesState.domicilioFiscal) {
            dispatch(
                changeErrDireccionDomicilioFiscal({
                    content: direccionDomicilioFiscal === '',
                })
            );
            if (direccionDomicilioFiscal === '') isValid = false;
        }
        if (aPersonalesState.representanteLegalRadio) {
            representantesLegales.map((item: any, i: any) => {
                Object.entries(item).map((key: any, keyI: any) => {
                    if (key[0] !== 'documentos') {
                        dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: key[1] === '' }));
                        if (key[0] === 'otro') {
                            dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: false }));
                        }
                        if (key[1] === '' && key[0] !== 'otro') isValid = false;
                    }
                    return null;
                });
                return null;
            });
        }

        return isValid;
    };

    return (
        <>
            <form id="form" action="">
                <div className="row">
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-9 col-md-9 col-sm-9 col-xs-5 input-box '}
                        label={'Rut/Pasaporte'}
                        state={aPersonalesState.rutPasaporte}
                        field={'rutPasaporte'}
                        error={errPersonalState.rutPasaporte}
                        reducer={changePersonalField}
                    />
                </div>
                <div className="row">
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-9 col-md-9 col-sm-9 col-xs-5 input-box '}
                        label={'Nombres'}
                        state={aPersonalesState.nombres}
                        field={'nombres'}
                        error={errPersonalState.nombres}
                        reducer={changePersonalField}
                    />
                </div>

                <div className="row">
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                        label={'Apellido Paterno'}
                        placeholder={''}
                        state={aPersonalesState.apellidoPaterno}
                        field={'apellidoPaterno'}
                        error={errPersonalState.apellidoPaterno}
                        reducer={changePersonalField}
                    />
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                        label={'Apellido Materno'}
                        state={aPersonalesState.apellidoMaterno}
                        field={'apellidoMaterno'}
                        error={errPersonalState.apellidoMaterno}
                        reducer={changePersonalField}
                    />
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                        label={'Fecha de nacimiento'}
                        type={'date'}
                        state={aPersonalesState.fechaNacimiento}
                        field={'fechaNacimiento'}
                        error={errPersonalState.fechaNacimiento}
                        reducer={changePersonalField}
                    />

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            {errPersonalState.paisNacimiento ? <span className="error-color">*</span> : ''}
                            Pais de Nacimiento
                        </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <select
                            className={`input-select input-activo ${
                                errPersonalState.paisNacimiento ? 'input-error' : ''
                            }`}
                            onChange={(e) =>
                                dispatch(changePersonalField({ field: 'paisNacimiento', content: e.target.value }))
                            }
                        >
                            {paises.map((pais, i) => {
                                return (
                                    <option key={i} value={pais.name}>
                                        {pais.name}
                                    </option>
                                );
                            })}
                        </select>
                        {errPersonalState.paisNacimiento && (
                            <p className="error-text error-color">*Campo obligatorio</p>
                        )}
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            {errPersonalState.sexo ? <span className="error-color">*</span> : ''}
                            Sexo
                        </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <select
                            className={`input-select input-activo ${errPersonalState.sexo ? 'input-error' : ''}`}
                            onChange={(e) => dispatch(changePersonalField({ field: 'sexo', content: e.target.value }))}
                        >
                            <option value={'M'}>Masculino</option>
                            <option value={'F'}>Femenino</option>
                            <option value={'O'}>Otro</option>
                        </select>
                        {errPersonalState.sexo && <p className="error-text error-color">*Campo obligatorio</p>}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            {errPersonalState.estadoCivil ? <span className="error-color">*</span> : ''}
                            Estado civil
                        </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <select
                            className={`input-select input-activo ${errPersonalState.estadoCivil ? 'input-error' : ''}`}
                            onChange={(e) =>
                                dispatch(changePersonalField({ field: 'estadoCivil', content: e.target.value }))
                            }
                        >
                            <option value={'Soltero'}>Soltero</option>
                            <option value={'Casado'}>Casado</option>
                            <option value={'Viudo'}>Viudo</option>
                            <option value={'Divorciado'}>Divorciado</option>
                            <option value={'CONVIVIENTE'}>conviviente civil AUC</option>
                        </select>
                        {errPersonalState.estadoCivil && <p className="error-text error-color">*Campo obligatorio</p>}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            {errPersonalState.nacionalidadPrincipal ? <span className="error-color">*</span> : ''}
                            Nacionalidad
                        </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <select
                            className={`input-select input-activo ${
                                errPersonalState.nacionalidadPrincipal ? 'input-error' : ''
                            }`}
                            onChange={(e) =>
                                dispatch(
                                    changePersonalField({ field: 'nacionalidadPrincipal', content: e.target.value })
                                )
                            }
                        >
                            {nacionalidad.map((nacionali, i) => {
                                return (
                                    <option key={i} value={nacionali.name}>
                                        {nacionali.name}
                                    </option>
                                );
                            })}
                        </select>
                        {errPersonalState.nacionalidadPrincipal && (
                            <p className="error-text error-color">*Campo obligatorio</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            ¿Eres US person?
                            <div className=" question-mark d-inline">
                                <FontAwesomeIcon
                                    className="ml-2"
                                    icon={faQuestionCircle}
                                    onClick={() => setShowModalUsPerson(true)}
                                />
                            </div>
                            <ModalUsPerson show={showModalUsPerson} setShow={setShowModalUsPerson} />
                        </label>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 d-flex justify-content-around align-items-center ">
                        <span className="input-radio-box">
                            <input
                                checked={aPersonalesState.usPerson}
                                className="input-radio"
                                type="radio"
                                name="USPerson"
                                value="Si"
                                onChange={(e) => dispatch(changePersonalField({ field: 'usPerson', content: true }))}
                            />{' '}
                            Si
                        </span>
                        <span className="input-radio-box">
                            <input
                                checked={!aPersonalesState.usPerson}
                                className="input-radio"
                                type="radio"
                                name="USPerson"
                                value="No"
                                onChange={(e) => dispatch(changePersonalField({ field: 'usPerson', content: false }))}
                            />
                            No
                        </span>
                    </div>

                    <div className="col-12 mt-5">
                        <div className="row">
                            <Input
                                label={'Teléfono'}
                                field={'telefono'}
                                state={aPersonalesState.telefono}
                                inputClass={'col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box'}
                                labelClass={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}
                                placeholder={'+56 9 74851205'}
                                error={errPersonalState.telefono}
                                reducer={changePersonalField}
                            />
                            <div className={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}>
                                <label>
                                    {errPersonalState.email ? <span className="error-color">*</span> : ''}
                                    {'Email'}
                                </label>
                            </div>
                            <div className={'col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box'}>
                                <input
                                    className={`input-activo ${errPersonalState.email ? 'input-error' : ''}`}
                                    type={type ? type : 'text'}
                                    value={aPersonalesState.email}
                                    onChange={(e) =>
                                        dispatch(changePersonalField({ field: 'email', content: e.target.value }))
                                    }
                                    placeholder={'email@gmail.com' ? 'email@gmail.com' : 'Email'}
                                />
                                {errPersonalState.email && <p className="error-text error-color">*Email inválido</p>}
                            </div>
                            <Input
                                label={'Dirección'}
                                field={'direccion'}
                                state={aPersonalesState.direccion}
                                inputClass={'col-lg-3 col-md-3 col-sm-4 col-xs-6 input-box'}
                                labelClass={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}
                                placeholder={'Dirección'}
                                error={errPersonalState.direccion}
                                reducer={changePersonalField}
                            />

                            <div className="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                                <div className="row">
                                    <Input
                                        label={'Número'}
                                        field={'numero'}
                                        state={aPersonalesState.numero}
                                        labelClass={'col-lg-2 col-md-2 col-sm-3 col-xs-6 input-box'}
                                        inputClass={'col-lg-4 col-md-4 col-sm-3 col-xs-6 input-box'}
                                        placeholder={'0000'}
                                        error={errPersonalState.numero}
                                        reducer={changePersonalField}
                                    />
                                    <Input
                                        label={'Dpto'}
                                        field={'dpto'}
                                        state={aPersonalesState.dpto}
                                        labelClass={'col-lg-2 col-md-2 col-sm-3 col-xs-6  input-box'}
                                        inputClass={'col-lg-4 col-md-4 col-sm-3 col-xs-6 input-box'}
                                        placeholder={'3 A'}
                                        error={errPersonalState.dpto}
                                        reducer={changePersonalField}
                                    />
                                </div>
                            </div>
                            <Input
                                labelClass={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}
                                inputClass={'col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box'}
                                label={'Ciudad'}
                                state={aPersonalesState.ciudad}
                                field={'ciudad'}
                                error={errPersonalState.ciudad}
                                reducer={changePersonalField}
                                placeholder={'Ciudad'}
                            />

                            <Input
                                labelClass={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}
                                inputClass={'col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box'}
                                label={'Comuna'}
                                state={aPersonalesState.comuna}
                                field={'comuna'}
                                error={errPersonalState.comuna}
                                reducer={changePersonalField}
                                placeholder={'Comuna'}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={`row d-flex ${aPersonalesState.otraNacionalidad ? 'justify-content-end' : ''}`}>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                                <label>Otra Nacionalidad</label>
                            </div>
                            <div
                                className={`col-lg-2 col-md-2 col-sm-2 col-xs-6 d-flex flex-column justify-content-around align-items-left mt-2 ${
                                    aPersonalesState.otraNacionalidad ? 'separation-bar-right:' : ''
                                }`}
                            >
                                <span className="input-radio-box">
                                    <input
                                        checked={aPersonalesState.otraNacionalidad}
                                        className="input-radio"
                                        type="radio"
                                        name="otraNacionalidad"
                                        value="Si"
                                        onChange={() =>
                                            dispatch(
                                                changePersonalField({
                                                    field: 'otraNacionalidad',
                                                    content: true,
                                                })
                                            )
                                        }
                                    />
                                    Si
                                </span>
                                <span className="input-radio-box">
                                    <input
                                        checked={!aPersonalesState.otraNacionalidad}
                                        className="input-radio"
                                        type="radio"
                                        name="otraNacionalidad"
                                        value="No"
                                        onChange={() =>
                                            dispatch(
                                                changePersonalField({
                                                    field: 'otraNacionalidad',
                                                    content: false,
                                                })
                                            )
                                        }
                                    />
                                    No
                                </span>
                            </div>
                            {aPersonalesState.otraNacionalidad &&
                                masNacionalidades.map((c: any, i: any) => {
                                    return (
                                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mt-3" key={i}>
                                            {i !== 0 && i === masNacionalidades.length - 1 ? (
                                                <div className={`row`}>
                                                    <div className="col-12 pru p-2">
                                                        <img
                                                            src={iconClose}
                                                            onClick={() => {
                                                                dispatch(removeNacionalidad({ index: i }));
                                                            }}
                                                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                                            alt="icon-close"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="row">
                                                        <div
                                                            className={`error-label-APNF col-lg-3 col-md-3 col-sm-3 col-xs-3 input-box`}
                                                        >
                                                            <label>
                                                                {errMasNacionalidades[i].nacionalidad ? (
                                                                    <span className="error-color">*</span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                ¿Cuál?
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 d-flex justify-content-center align-items-center">
                                                            <select
                                                                className={`input-select input-activo ${
                                                                    errMasNacionalidades[i].nacionalidad
                                                                        ? 'input-error'
                                                                        : ''
                                                                }`}
                                                                onChange={(e) =>
                                                                    dispatch(
                                                                        changeNacionalidadesField({
                                                                            index: i,
                                                                            content: e.target.value,
                                                                        })
                                                                    )
                                                                }
                                                            >
                                                                {nacionalidad.map((nacionali, i) => {
                                                                    return (
                                                                        <option key={i} value={nacionali.name}>
                                                                            {nacionali.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div
                                                            className={`col-lg-3 col-md-3 col-sm-3 col-xs-3 input-box`}
                                                        >
                                                            <label>
                                                                {errMasNacionalidades[i].direccion ? (
                                                                    <span className="error-color">*</span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                Dirección
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 d-flex justify-content-center align-items-center">
                                                            <input
                                                                className={`input-activo ${
                                                                    errMasNacionalidades[i].direccion
                                                                        ? 'input-error'
                                                                        : ''
                                                                }`}
                                                                placeholder="Dirección"
                                                                type="text"
                                                                value={masNacionalidades[i].direccion}
                                                                onChange={(e) =>
                                                                    dispatch(
                                                                        changeNacionalidadDireccionField({
                                                                            index: i,
                                                                            content: e.target.value,
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                            {errMasNacionalidades[i].direccion && (
                                                                <p className="error-text-APNF error-color">
                                                                    *Campo obligatorio
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            {aPersonalesState.otraNacionalidad && (
                                <div className="col-12 mt-2 mb-2">
                                    <div className="row d-flex justify-content-end">
                                        <div className=" error-label-APNF col-md-4 col-sm-4">
                                            <div
                                                className={`btn btn-avante-secondary-white btn-home d-flex align-items-center justify-content-center`}
                                                onClick={() => dispatch(addAnotherNacionalidad())}
                                            >
                                                Agregar otra nacionalidad
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                                <label>Domicilio fiscal en otro pais</label>
                            </div>
                            <div
                                className={`col-lg-2 col-md-2 col-sm-2 col-xs-6 d-flex flex-column justify-content-around align-items-left mt-2 ${
                                    aPersonalesState.domicilioFiscal ? 'separation-bar-right' : ''
                                }`}
                            >
                                <span className="input-radio-box">
                                    <input
                                        checked={aPersonalesState.domicilioFiscal}
                                        className="input-radio"
                                        type="radio"
                                        name="domicilioFiscal"
                                        value="Si"
                                        onChange={(e) =>
                                            dispatch(changePersonalField({ field: 'domicilioFiscal', content: true }))
                                        }
                                    />{' '}
                                    Si
                                </span>
                                <span className="input-radio-box">
                                    <input
                                        checked={!aPersonalesState.domicilioFiscal}
                                        className="input-radio"
                                        type="radio"
                                        name="domicilioFiscal"
                                        value="No"
                                        onChange={() =>
                                            dispatch(changePersonalField({ field: 'domicilioFiscal', content: false }))
                                        }
                                    />{' '}
                                    No
                                </span>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                                {aPersonalesState.domicilioFiscal && (
                                    <div className="row mt-3">
                                        <div className="col-3 input-box">
                                            <label>
                                                {errDireccionDomicilioFiscal ? (
                                                    <span className="error-color">*</span>
                                                ) : (
                                                    ''
                                                )}
                                                Dirección
                                            </label>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 ">
                                            <input
                                                className={`input-activo ${
                                                    errDireccionDomicilioFiscal ? 'input-error' : ''
                                                }`}
                                                placeholder="Dirección"
                                                type="text"
                                                value={direccionDomicilioFiscal}
                                                onChange={(e) =>
                                                    dispatch(
                                                        changeDireccionDomicilioFiscal({
                                                            content: e.target.value,
                                                        })
                                                    )
                                                }
                                            />
                                            {errDireccionDomicilioFiscal && (
                                                <p className="error-text error-color">*Campo obligatorio</p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-12 input-box">
                                <label htmlFor="">¿Tiene usted un representante legal o mandatario?</label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                                    <span className="input-radio-box mb-2">
                                        <input
                                            className="input-radio"
                                            type="radio"
                                            value="Si"
                                            checked={aPersonalesState.representanteLegalRadio}
                                            onChange={() => {
                                                dispatch(
                                                    changePersonalField({
                                                        field: 'representanteLegalRadio',
                                                        content: true,
                                                    })
                                                );
                                            }}
                                        />
                                        Si
                                    </span>
                                    <span className="input-radio-box">
                                        <input
                                            className="input-radio"
                                            type="radio"
                                            value="No"
                                            checked={!aPersonalesState.representanteLegalRadio}
                                            onChange={() => {
                                                dispatch(
                                                    changePersonalField({
                                                        field: 'representanteLegalRadio',
                                                        content: false,
                                                    })
                                                );
                                            }}
                                        />
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {aPersonalesState.representanteLegalRadio ? (
                        <div className="col-12 representante-legal-form">
                            {representantesLegales.map((rep: any, i: any) => {
                                return <RepresentanteLegalForm key={i} index={i} />;
                            })}
                            <div className="row sparation-bar mt-2 mb-2"></div>
                            <div className="row mb-2">
                                <div className="col-8 input-box">
                                    <label htmlFor="">¿Quisiera agregar otro representante legal o mandatario?</label>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div
                                        className="btn-avante-secondary-white btn-home d-flex align-items-center justify-content-center"
                                        onClick={() => dispatch(addRepresentanteLegal())}
                                    >
                                        Agregar
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-12 input-box">
                                <label className="mr-2" htmlFor="">
                                    ¿Te calificas como persona políticamente expuesta? &nbsp;
                                    <FontAwesomeIcon
                                        style={{ color: '#233746' }}
                                        icon={faQuestionCircle}
                                        onMouseEnter={() => setshowPopoverPolitica(true)}
                                        onMouseLeave={() => setshowPopoverPolitica(false)}
                                    />
                                </label>
                                <div className={`popover-politico ${!showPopoverPolitica ? 'hide' : ''}`}>
                                    {popoverPolitica}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="col-6 d-flex flex-column justify-content-around align-items-left mt-2 ">
                                    <span className="input-radio-box mb-2">
                                        <input
                                            className="input-radio"
                                            type="radio"
                                            checked={aPersonalesState.personaPolitica}
                                            name="personaPolitica"
                                            value="Si"
                                            onChange={() =>
                                                dispatch(
                                                    changePersonalField({ field: 'personaPolitica', content: true })
                                                )
                                            }
                                        />
                                        Si
                                    </span>
                                    <span className="input-radio-box">
                                        <input
                                            className="input-radio"
                                            type="radio"
                                            checked={!aPersonalesState.personaPolitica}
                                            name="personaPolitica"
                                            value="No"
                                            onChange={() =>
                                                dispatch(
                                                    changePersonalField({ field: 'personaPolitica', content: false })
                                                )
                                            }
                                        />
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 sparation-bar mt-2 mb-2"></div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 cedula-title">
                                <span>
                                    {errPersonalState.cedulaDorso || errPersonalState.cedulaFrente ? (
                                        <span className="error-color">*</span>
                                    ) : (
                                        ''
                                    )}
                                    Cédula de Identidad
                                </span>
                                {(errPersonalState.cedulaDorso || errPersonalState.cedulaFrente) && (
                                    <p className="error-text error-color mb-3">*Campo obligatorio</p>
                                )}
                            </div>
                            <div className="col-lg-8 col-sm-6 cedula-instruccion">
                                <span>
                                    Sube un imagen de tu Cédula de identidad por ambos lados. (Formato JPG, PNG ó PDF
                                    con un máximo de 9MB)
                                </span>
                            </div>
                            <div className="col-lg-4 col-sm-6 cedula-button">
                                <div
                                    className="btn btn-avante-secondary btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => setShowModalCedula(true)}
                                >
                                    Subir Cédula
                                    <ModalCedula show={showModalCedula} setShow={setShowModalCedula} />
                                </div>
                            </div>
                            {aPersonalesState.cedulaDorso.length !== 0 ||
                                (aPersonalesState.cedulaFrente.length !== 0 && (
                                    <span className="col-12 cedula-sub-title">Documentos Adjuntos:</span>
                                ))}
                            <div className="col-lg-8 col-sm-8 cedula-button">
                                {aPersonalesState.cedulaFrente.map((el: any, i: number) => {
                                    return (
                                        <div className="row">
                                            <div key={i} className="col-8 d-flex text-center ">
                                                <span className="mr-3">{el.path} </span>
                                            </div>
                                            <div className="col-4 d-flex align-items-center justify-content-center">
                                                <img
                                                    style={{ width: '16px', cursor: 'pointer' }}
                                                    src={iconClose}
                                                    alt=""
                                                    onClick={() => dispatch(removeCedulaFrente({ index: i }))}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                {aPersonalesState.cedulaDorso.map((el: any, i: number) => {
                                    return (
                                        <div className="row">
                                            <div key={i} className="col-8 d-flex text-center ">
                                                <span className="mr-3">{el.path} </span>
                                            </div>
                                            <div className="col-4 d-flex align-items-center justify-content-center">
                                                <img
                                                    style={{ width: '16px', cursor: 'pointer' }}
                                                    src={iconClose}
                                                    alt=""
                                                    onClick={() => dispatch(removeCedulaDorso({ index: i }))}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5 mb-3">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                                <div
                                    className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => history.push(`/cuestionario/${type}`)}
                                >
                                    Anterior
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                                <div
                                    className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        if (validateAPersonalesData()) {
                                            setFormStep(1);
                                        }
                                    }}
                                >
                                    Continuar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
