import React from 'react'

import './NotFound.scss'

export default function index() {
  return (
    <div className='notFound'>
      <h1>NOT FOUND</h1>
      <h1>ERROR 404</h1>
    </div>
  )
}
