import React from 'react';
import { useHistory } from 'react-router-dom';
import { Boton } from '../Boton/Boton';
import './ResultadoCuestionario.scss';

export const ResultadoCuestionario = ({ type, perfiles, setStep }: any) => {
    const history = useHistory();
    return (
        <div className="result-box">
            <p className="result-title">{`Eres un perfil ${perfiles.titulo}`}</p>
            <p className="result-text">{perfiles.texto}</p>
            <div style={{ width: '50%', margin: '3rem auto' }}>
                <Boton
                    text="Continuar"
                    filled={true}
                    onClickFunc={() => {
                        setStep(2);
                        history.push(`/datos-personales/${type}`);
                    }}
                />
            </div>
        </div>
    );
};
