import React from 'react';
import { useDispatch } from 'react-redux';
export const Input = ({ label, error, state, field, labelClass, inputClass, type, placeholder, reducer }: any) => {
    const dispatch = useDispatch();
    return (
        <>
            <div className={labelClass}>
                <label>
                    {error ? <span className="error-color">*</span> : ''}
                    {label}
                </label>
            </div>
            <div className={inputClass}>
                <input
                    className={`input-activo ${error ? 'input-error' : ''}`}
                    type={type ? type : 'text'}
                    value={state}
                    onChange={(e) => dispatch(reducer({ field: field, content: e.target.value }))}
                    placeholder={placeholder ? placeholder : label}
                />
                {error && <p className="error-text error-color">*Campo obligatorio</p>}
            </div>
        </>
    );
};
