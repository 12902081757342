import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import iconUpload from '../../assets/svg/upload-icon.svg';
import iconClose from '../../assets/svg/icon-close.svg';
import './ModalRepresentanteLegal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { addDocumentosRepresentaneLegal, removeDocumentosRepresentaneLegal } from '../../redux/reducers/naturalForm';
export const ModalRepresentanteLegal = ({ show, setShow, index }: any) => {
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            setFiles(acceptedFiles);
        },
    });

    useEffect(() => {
        if (files.length !== 0) dispatch(addDocumentosRepresentaneLegal({ index: index, file: files[0] }));
    }, [files, dispatch, index]);

    const documents = useSelector((state: any) => state.naturalForm.representantesLegales[index].documentos);

    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} size="lg" centered>
            <ModalBody style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end align-center">
                        <img
                            src={iconClose}
                            onClick={() => setShow(!show)}
                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            alt="icon-close"
                        />
                    </div>
                </div>
                <div className="row d-flex align-center justify-content-center">
                    <div className="col-8">
                        <div className="row">
                            <div className="col-12 p-0 mb-2 ">
                                <span className="modal-title">Documentos</span>
                            </div>
                            <div
                                {...getRootProps()}
                                className="col-12 d-flex justify-content-center align-center upload-file-box"
                            >
                                <input {...getInputProps()} />
                                <div className="row d-flex justify-content-center align-center">
                                    <div className="col-5 d-flex justify-content-center align-center mb-3">
                                        <img src={iconUpload} alt="icon-upload" />
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 text-center mb-4">
                                                <span>Arrasta tus documentos aquí.</span>
                                            </div>
                                            <div className="col-12 text-center">
                                                (Formato JPG, PNG ó PDF con un máximo de 9MB)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {documents.map((el: any, i: number) => {
                                return (
                                    <div key={i} className="d-flex justify-content-around align-items-center mr-4">
                                        <span className="mr-3">{el.path} </span>
                                        <img
                                            style={{ width: '16px', cursor: 'pointer' }}
                                            src={iconClose}
                                            alt=""
                                            onClick={() =>
                                                dispatch(
                                                    removeDocumentosRepresentaneLegal({
                                                        repIndex: index,
                                                        docIndex: i,
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                            <div className="col-12 mt-4">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                        <div
                                            className="btn btn-primary btn-home btn-avante-primary d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                setShow(!show);
                                            }}
                                        >
                                            Continuar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
