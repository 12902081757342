export const representanteLegal = {
    rutPasaporte: '',
    nombres: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    telefono: '',
    email: '',
    direccion: '',
    numero: '',
    dpto: '',
    ciudad: '',
    comuna: '',
    relacion: '',
    rutRepresentanteLegalFrente: [],
    rutRepresentanteLegalDorso: [],
};
export const errRepresentanteLegal = {
    rutPasaporte: false,
    nombres: false,
    apellidoPaterno: false,
    apellidoMaterno: false,
    telefono: false,
    email: false,
    direccion: false,
    numero: false,
    dpto: false,
    ciudad: false,
    comuna: false,
    relacion: false,
    rutRepresentanteLegalFrente: false,
    rutRepresentanteLegalDorso: false,
};
export const representantesLegales = [
    {
        rutPasaporte: '',
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        telefono: '',
        email: '',
        direccion: '',
        numero: '',
        dpto: '',
        ciudad: '',
        comuna: '',
        relacion: '',
        rutRepresentanteLegalFrente: [],
        rutRepresentanteLegalDorso: [],
    },
];
export const errRepresentantesLegales = [
    {
        rutPasaporte: false,
        nombres: false,
        apellidoPaterno: false,
        apellidoMaterno: false,
        telefono: false,
        email: false,
        direccion: false,
        numero: false,
        dpto: false,
        ciudad: false,
        comuna: false,
        relacion: false,
        rutRepresentanteLegalFrente: false,
        rutRepresentanteLegalDorso: false,
    },
];
