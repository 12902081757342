import React, { useState } from 'react';
import 'antd/dist/antd.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import '../../../assets/scss/popover.scss';
import { FormularioAgente } from '../../FormularioAgente/FormularioAgente';
import { ModalAnexoBNatural } from '../../ModalAnexoBNatural/ModalAnexoBNatural';
import { ModalAnexoANatural } from '../../ModalAnexoANatural/ModalAnexoANatural';
import { useDispatch, useSelector } from 'react-redux';
import {
    addContactoAdicional,
    changeContactoAdicionalField,
    changeDatosFinalesField,
    // changeAgenteError,
} from '../../../redux/reducers/naturalForm';
import { changeErrDatosFinales } from '../../../redux/reducers/naturalForm';
import { removeContactoAdicional } from '../../../redux/reducers/naturalForm';
import { changeContactoAdicionalError } from '../../../redux/reducers/naturalForm';
import iconClose from '../../../assets/svg/icon-close.svg';

const popoverInversionista =
    '“La normativa considera que se puede clasificar en dicha categoría a todos los inversionistas, los inversionistas institucionales, a los intermediarios de valores y a las personas naturales o jurídicas que declaren y acrediten contar con inversiones financieras no inferiores a 2.000 UF, y que adicionalmente cumplan con alguno de los siguientes requisitos: Contar con activos iguales o superiores a 10.000 UF. Haber realizado transacciones en el Mercado de Valores por un monto igual o superior a 1.000 UF, con una frecuencia mínima de 20 operaciones trimestrales, durante los últimos 4 años, o Contar con el conocimiento necesario para entender los riesgos que conlleva invertir en el mercado de valores.”';

const popoverAdministrador =
    '“Es usted administrador de cartera inscrito en el Registro de Administradores de Carteras que lleva la Comisión para el Mercado Financiero que Administra carteras para 50 o más mandantes no pertenecientes a la misma familia, conforme a la definición contenida en la letra d) del artículo 1° de la LUF, por un monto total igual o superior al equivalente a 500.000 Unidades de Fomento; o Administra una o más carteras por un monto total igual o superior al equivalente a 1.000.000 Unidades de Fomento.”';

export const DatosFinalesNaturalForm = ({ setFormStep }: any) => {
    const history = useHistory();
    const [showPopoverInversionista, setShowPopoverInversionista] = useState(false);
    const [showPopoverAdministrador, setShowPopoverAdministrador] = useState(false);
    const [showAnexoB, setShowAnexoB] = useState(false);
    const [showAnexoA, setShowAnexoA] = useState(false);
    const datosFinales = useSelector((state: any) => state.naturalForm.datosFinales);
    const errDatosFinales = useSelector((state: any) => state.naturalForm.errDatosFinales);
    const contactosAdicionales = useSelector((state: any) => state.naturalForm.contactosAdicionales);
    const errContactosAdicionales = useSelector((state: any) => state.naturalForm.errContactosAdicionales);
    // const agenteData = useSelector((state: any) => state.naturalForm.agente);

    const dispatch = useDispatch();

    // const validateAgenteData = () => {
    //     let isValid = true;
    //     if (datosFinales.agente) {
    //         Object.entries(agenteData).map((item: any) => {
    //             if (item[0] === 'rolUnicoTributario') {
    //             dispatch(changeAgenteError({ field: item[0], content: item[1] === '' }));
    //             if (item[1] === '') isValid = false;
    //             }

    //             return null;
    //         });
    //     }
    //     return isValid;
    // };
    const validateDatosFinales = () => {
        let isValid = true;
        if (datosFinales.inversionistaCalificado) {
            dispatch(changeErrDatosFinales({ field: 'anexoB', content: !datosFinales.anexoB }));
            isValid = datosFinales.anexoB;
        }
        if (datosFinales.administradorDeCartera) {
            dispatch(changeErrDatosFinales({ field: 'anexoA', content: !datosFinales.anexoA }));
            isValid = datosFinales.anexoA;
        }
        return isValid;
    };

    const validateContactoAdicionales = () => {
        let isValid = true;
        if (datosFinales.contactoAdicional) {
            contactosAdicionales.map((item: any, i: any) => {
                Object.entries(item).map((key: any, keyI: any) => {
                    dispatch(changeContactoAdicionalError({ index: i, field: key[0], content: key[1] === '' }));
                    if (key[1] === '') isValid = false;
                    return null;
                });
                return null;
            });
        }

        return isValid;
    };

    return (
        <div>
            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-12 input-box" style={{ color: '#233746' }}>
                        <label className="mr-2" htmlFor="">
                            ¿Es Usted inversionista calificado?{' '}
                        </label>

                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            onMouseEnter={() => setShowPopoverInversionista(true)}
                            onMouseLeave={() => setShowPopoverInversionista(false)}
                        />
                        <div className={`popover-custom ${!showPopoverInversionista ? 'hide' : ''}`}>
                            {popoverInversionista}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                            <span className="input-radio-box mb-2">
                                <input
                                    checked={datosFinales.inversionistaCalificado}
                                    className="input-radio"
                                    type="radio"
                                    name="inversionistaCalificado"
                                    onChange={() => {
                                        dispatch(
                                            changeDatosFinalesField({ field: 'inversionistaCalificado', content: true })
                                        );
                                        setShowAnexoB(true);
                                    }}
                                />
                                Si
                            </span>
                            <span className="input-radio-box">
                                <input
                                    checked={!datosFinales.inversionistaCalificado}
                                    className="input-radio"
                                    type="radio"
                                    name="inversionistaCalificado"
                                    onChange={() =>
                                        dispatch(
                                            changeDatosFinalesField({
                                                field: 'inversionistaCalificado',
                                                content: false,
                                            })
                                        )
                                    }
                                />
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {datosFinales.inversionistaCalificado && (
                <div className="col-12 d-flex flex-column justify-content-around align-items-left ">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        {errDatosFinales.anexoB ? <span className="error-color">*</span> : ''}
                        <input
                            checked={datosFinales.anexoB}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="anexoB"
                            value="Si"
                            // onChange={() =>
                            //     dispatch(changeDatosFinalesField({ field: 'anexoB', content: !datosFinales.anexoB }))
                            // }
                        />
                        Acepto las condiciones que se informan en el Anexo B{' '}
                        <span className="link-style" onClick={() => setShowAnexoB(true)}>
                            Leer y completar Anexo B
                        </span>
                        {errDatosFinales.anexoB && <p className="error-text-NDF error-color">*Campo obligatorio</p>}
                        <ModalAnexoBNatural show={showAnexoB} setShow={setShowAnexoB} />
                    </span>
                </div>
            )}

            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-12 error-label-NDF input-box" style={{ color: '#233746' }}>
                        <label className="mr-2" htmlFor="">
                            ¿Es Usted administrador de cartera?{' '}
                        </label>
                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            onMouseEnter={() => setShowPopoverAdministrador(true)}
                            onMouseLeave={() => setShowPopoverAdministrador(false)}
                        />
                        <div className={`popover-custom ${!showPopoverAdministrador ? 'hide' : ''}`}>
                            {popoverAdministrador}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                            <span className="input-radio-box mb-2">
                                <input
                                    checked={datosFinales.administradorDeCartera}
                                    className="input-radio"
                                    type="radio"
                                    name="administradorDeCartera"
                                    value="Si"
                                    onChange={() => {
                                        dispatch(
                                            changeDatosFinalesField({ field: 'administradorDeCartera', content: true })
                                        );
                                        setShowAnexoA(true);
                                    }}
                                />
                                Si
                            </span>
                            <span className="input-radio-box">
                                <input
                                    checked={!datosFinales.administradorDeCartera}
                                    className="input-radio"
                                    type="radio"
                                    name="administradorDeCartera"
                                    value="No"
                                    onChange={() =>
                                        dispatch(
                                            changeDatosFinalesField({ field: 'administradorDeCartera', content: false })
                                        )
                                    }
                                />
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {datosFinales.administradorDeCartera && (
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        {errDatosFinales.anexoA ? <span className="error-color">*</span> : ''}
                        <input
                            checked={datosFinales.anexoA}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="anexoA"
                            value="Si"
                            // onChange={() =>
                            //     dispatch(changeDatosFinalesField({ field: 'anexoA', content: !datosFinales.anexoA }))
                            // }
                        />
                        Acepto las condiciones que se informan en el Anexo A{' '}
                        <span
                            className="link-style"
                            onClick={() => {
                                setShowAnexoA(true);
                            }}
                        >
                            Leer y completar Anexo A
                        </span>
                        {errDatosFinales.anexoA && <p className="error-text-NDF error-color">*Campo obligatorio</p>}
                        <ModalAnexoANatural show={showAnexoA} setShow={setShowAnexoA} />
                    </span>
                </div>
            )}

            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-12 error-label-NDF input-box" style={{ color: '#233746' }}>
                        <label htmlFor="">¿Quisiera agregar algún contacto adicional? (Contador, abogado, etc.) </label>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="col-6 d-flex flex-column justify-content-around align-items-left mt-2 ">
                            <span className="input-radio-box mb-2">
                                <input
                                    checked={datosFinales.contactoAdicional}
                                    className="input-radio"
                                    type="radio"
                                    name="personaPolitica"
                                    value="Si"
                                    onChange={() =>
                                        dispatch(changeDatosFinalesField({ field: 'contactoAdicional', content: true }))
                                    }
                                />
                                Si
                            </span>
                            <span className="input-radio-box">
                                <input
                                    checked={!datosFinales.contactoAdicional}
                                    className="input-radio"
                                    type="radio"
                                    name="personaPolitica"
                                    value="No"
                                    onChange={() =>
                                        dispatch(
                                            changeDatosFinalesField({ field: 'contactoAdicional', content: false })
                                        )
                                    }
                                />
                                No
                            </span>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        {datosFinales.contactoAdicional &&
                            contactosAdicionales.map((contacto: any, i: any) => {
                                return (
                                    <div className="row" key={i}>
                                        {i !== 0 && i === contactosAdicionales.length - 1 ? (
                                            <div className="col-12 d-flex justify-content-end align-center p-2">
                                                <img
                                                    src={iconClose}
                                                    onClick={() => {
                                                        dispatch(removeContactoAdicional({ index: i }));
                                                    }}
                                                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                                    alt="icon-close"
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="col-lg-4 col-md-4 col-sm-12 ">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="" className="input-box m-0">
                                                        {errContactosAdicionales[i].relacion ? (
                                                            <span className="error-color">*</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        Relación
                                                    </label>
                                                </div>
                                                <div className="col-12 input-box">
                                                    <input
                                                        className={`input-activo ${
                                                            errContactosAdicionales[i].relacion ? 'input-error' : ''
                                                        }`}
                                                        type="text"
                                                        value={contactosAdicionales[i].relacion}
                                                        onChange={(e) => {
                                                            dispatch(
                                                                changeContactoAdicionalField({
                                                                    index: i,
                                                                    field: 'relacion',
                                                                    content: e.target.value,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    {errContactosAdicionales[i].relacion && (
                                                        <p className="error-text  error-color">*Campo obligatorio</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="" className="input-box m-0">
                                                        {errContactosAdicionales[i].nombre ? (
                                                            <span className="error-color">*</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        Nombre
                                                    </label>
                                                </div>
                                                <div className="col-12 input-box">
                                                    <input
                                                        className={`input-activo ${
                                                            errContactosAdicionales[i].nombre ? 'input-error' : ''
                                                        }`}
                                                        type="text"
                                                        value={contactosAdicionales[i].nombre}
                                                        onChange={(e) => {
                                                            dispatch(
                                                                changeContactoAdicionalField({
                                                                    index: i,
                                                                    field: 'nombre',
                                                                    content: e.target.value,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    {errContactosAdicionales[i].nombre && (
                                                        <p className="error-text error-color">*Campo obligatorio</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="" className="input-box m-0">
                                                        {errContactosAdicionales[i].email ? (
                                                            <span className="error-color">*</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        Email
                                                    </label>
                                                </div>
                                                <div className="col-12 input-box">
                                                    <input
                                                        className={`input-activo ${
                                                            errContactosAdicionales[i].email ? 'input-error' : ''
                                                        }`}
                                                        type="text"
                                                        value={contactosAdicionales[i].email}
                                                        onChange={(e) => {
                                                            dispatch(
                                                                changeContactoAdicionalField({
                                                                    index: i,
                                                                    field: 'email',
                                                                    content: e.target.value,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    {errContactosAdicionales[i].email && (
                                                        <p className="error-text error-color">*Campo obligatorio</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    {datosFinales.contactoAdicional && (
                        <div className="col-12 mt-2 mb-2">
                            <div className="row d-flex justify-content-start">
                                <div className="col-lg-4 col-md-4 col-sm-4 ">
                                    <div
                                        className="btn btn-primary btn-avante-secondary-white btn-home d-flex align-items-center justify-content-center"
                                        onClick={() => {
                                            dispatch(addContactoAdicional());
                                        }}
                                    >
                                        Agregar otro contacto
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-12 input-box" style={{ color: '#233746' }}>
                                <label className="mr-2" htmlFor="">
                                    ¿Actua a través de un agente?{' '}
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                                    <span className="input-radio-box mb-2">
                                        <input
                                            checked={datosFinales.agente}
                                            className="input-radio"
                                            type="radio"
                                            name="agente"
                                            onChange={() => {
                                                dispatch(changeDatosFinalesField({ field: 'agente', content: true }));
                                            }}
                                        />
                                        Si
                                    </span>
                                    <span className="input-radio-box">
                                        <input
                                            checked={!datosFinales.agente}
                                            className="input-radio"
                                            type="radio"
                                            name="agente"
                                            onChange={() =>
                                                dispatch(
                                                    changeDatosFinalesField({
                                                        field: 'agente',
                                                        content: false,
                                                    })
                                                )
                                            }
                                        />
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {datosFinales.agente && <FormularioAgente></FormularioAgente>}

                    <div className="col-12 mt-5 mb-3">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                                <div
                                    className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        setFormStep(1);
                                    }}
                                >
                                    Anterior
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                                <div
                                    className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        if (
                                            // validateAgenteData() &&
                                            validateDatosFinales() &&
                                            validateContactoAdicionales()
                                        ) {
                                            history.push('/contrato/naturalForm');
                                        }
                                        //history.push('/final/naturalForm');
                                    }}
                                >
                                    Acepto las condiciones
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
