import React from 'react'
export const Boton = ({text,onClickFunc,filled}:any) => {

    return (
        <button
        className={`btn btn-primary btn-avante-primary px-5 ${filled?'btn-avante-primary':'btn-avante-primary-white'}`}
         onClick={()=>onClickFunc()}>
        {text}
        </button>
    )
}
 
