import React from 'react';
import { useParams } from 'react-router-dom';
import { JuridicaForm } from '../JuridicaForm/JuridicaForm';
import Layout from '../Layout/Layout';
import { NaturalForm } from '../NaturalForm/NaturalForm';
import { Stepper } from '../Stepper/Stepper';

export const DatosPersonales = () => {
    const {type}:any = useParams()
    return (
        <>
            <Layout>
                <div className="container page-container text-center">
                    <div className='text-center'>
                        <span className='cuestionario-title'>
                            Por favor complete los siguientes datos
                        </span>
                    </div>
                </div>
                <Stepper step={2}/>
                {
                    type === 'natural'?
                        <NaturalForm/>
                        :
                        <JuridicaForm/>
                }
            </Layout>
        </>
  )
};

