export const aCliente = {
    rutPasaporte: '',
    paisConstitucion: 'Chile',
    usPerson: false,
    telefono: '',
    email: '',
    direccion: '',
    numero: '',
    dpto: '',
    ciudad: '',
    comuna: '',
    domicilioFiscal: false,
    giroSociedad: '',
    politicamenteExpuesta: false,
    nombreRazonSocial: '',
    rutSociedadFrente: [],
    rutSociedadDorso: [],
};

export const errACliente = {
    rutPasaporte: false,
    paisConstitucion: false,
    usPerson: false,
    telefono: false,
    email: false,
    direccion: false,
    numero: false,
    dpto: false,
    ciudad: false,
    domicilioFiscal: false,
    politicamenteExpuesta: false,
    comuna: false,
    giroSociedad: false,
    nombreRazonSocial: false,
    rutSociedadFrente: false,
    rutSociedadDorso: false,
};

export const masNacionalidades = [{ nacionalidad: 'Chilena', direccion: '' }];

export const errMasNacionalidades = [{ nacionalidad: false, direccion: false }];
