export const datosFinales = {
    inversionistaCalificado: false,
    administradorDeCartera: false,
    contactoAdicional: false,
    anexoB: false,
    anexoA: false,
    agente: false,
};
export const errDatosFinales = {
    anexoB: false,
    anexoA: false,
};
export const contactosAdicionales = [{ relacion: '', nombre: '', email: '' }];
export const errContactosAdicionales = [{ relacion: false, nombre: false, email: false }];

export const contacto = { relacion: '', nombre: '', email: '' };
export const errContacto = { relacion: false, nombre: false, email: false };

export const anexoBData = {
    personaNaturalChilena: 'X',
    direccionExtranjera: '',
    checkbox1: 'O',
    checkbox2: 'O',
    checkbox3: 'O',
    checkbox4: 'O',
    checkbox5: 'O',
    nombre: '',
    nroCedula: '',
    nroIdTributario: '',
    nroRutExtranjero: '',
};
export const anexoBErrors = {
    direccionExtranjera: false,
    nombre: false,
    nroCedula: false,
    nroIdTributario: false,
    nroRutExtranjero: false,
};

export const anexoAData = {
    checkbox1: 'O',
    checkbox2: 'O',
    nombre: '',
    nroCedula: '',
    nroIdTributario: '',
    nroRutExtranjero: '',
};
export const anexoAErrors = {
    nombre: false,
    nroCedula: false,
    nroIdTributario: false,
    nroRutExtranjero: false,
};

export const agente = {
    rolUnicoTributario: '',
    nombreRazonSocial: '',
    nombreCompletoRepresentante: '',
    rolUnicoTributarioRepresentante: '',
    comuna: '',
    ciudad: '',
    telefono: '',
    email: '',
};
export const errAgente = {
    rolUnicoTributario: false,
    nombreRazonSocial: false,
    nombreCompletoRepresentante: false,
    rolUnicoTributarioRepresentante: false,
    comuna: false,
    ciudad: false,
    telefono: false,
    email: false,
};
