import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Home } from '../pages/Home/Home';

import NotFound from '../components/NotFound';

//Importing routes modules
import Cuestionario from '../components/Cuestionario/Cuestionario';
import { DatosPersonales } from '../components/DatosPersonales/DatosPersonales';
import { Contrato } from '../components/Contrato/Contrato';
import { Final } from '../components/Final/Final';
import { Thanks } from '../components/Thanks/Thanks';
import { ScrollToTop } from '../components/ScrollToTop/ScrollToTop';

export const AppRouter = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/cuestionario/:type" component={Cuestionario} />
                <Route exact path="/datos-personales/:type" component={DatosPersonales} />
                <Route exact path="/contrato/:type" component={Contrato} />
                <Route exact path="/final" component={Final} />
                <Route exact path="/thanks" component={Thanks} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};
