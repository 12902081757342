export const datosFinales = {
    inversionistaInstitucional: false,
    inversionistaCalificado: false,
    contactoAdicional: false,
    anexoN1: false,
    anexoN2: false,
    agente: false,
};
export const errDatosFinales = {
    anexoN1: false,
    anexoN2: false,
};

export const anexoN1 = {
    checkbox1: 'O',
    checkbox2: 'O',
    checkbox3: 'O',
    checkbox4: 'O',
    checkbox5: 'O',
    checkbox6: 'O',
    checkbox7: 'O',
    checkbox8: 'O',
    checkbox9: 'O',
    checkbox10: 'O',
    checkbox11: 'O',
    checkbox12: 'O',
    checkbox13: 'O',
    checkbox14: 'O',
    checkbox15: 'O',
    checkbox16: 'O',
    checkbox17: 'O',
    nombreRepresentanteLegal: '',
    nroCedulaId: '',
    nombreRazonSocial: '',
    nroRut: '',
    nroIdTributaria: '',
    nroRutExtranjero: '',
};
export const errAnexoN1 = {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    checkbox10: false,
    checkbox11: false,
    checkbox12: false,
    checkbox13: false,
    checkbox14: false,
    checkbox15: false,
    checkbox16: false,
    checkbox17: false,
    nombreRepresentanteLegal: false,
    nroCedulaId: false,
    nombreRazonSocial: false,
    nroRut: false,
    nroIdTributaria: false,
    nroRutExtranjero: false,
};

export const anexoN2 = {
    entidadNacional: 'X',
    domicilioExtranjero: '',
    checkbox1: 'O',
    checkbox2: 'O',
    checkbox3: 'O',
    checkbox4: 'O',
    checkbox5: 'O',
    nombreRepresentanteLegal: '',
    nroCedulaId: '',
    nombreRazonSocial: '',
    nroRut: '',
    nroIdTributaria: '',
    nroRutExtranjero: '',
};
export const errAnexoN2 = {
    domicilioExtranjero: false,
    nombreRepresentanteLegal: false,
    nroCedulaId: false,
    nombreRazonSocial: false,
    nroRut: false,
    nroIdTributaria: false,
    nroRutExtranjero: false,
};

export const agente = {
    rolUnicoTributario: '',
    nombreRazonSocial: '',
    nombreCompletoRepresentante: '',
    rolUnicoTributarioRepresentante: '',
    comuna: '',
    ciudad: '',
    telefono: '',
    email: '',
    direccion: '',
};
export const errAgente = {
    rolUnicoTributario: false,
    nombreRazonSocial: false,
    nombreCompletoRepresentante: false,
    rolUnicoTributarioRepresentante: false,
    comuna: false,
    ciudad: false,
    telefono: false,
    email: false,
    direccion: false,
};
