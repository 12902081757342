import { createSlice } from '@reduxjs/toolkit';
import { aCliente, errACliente, errMasNacionalidades, masNacionalidades } from './initialStates/juridicaForm/aCliente';
import {
    anexoN1,
    anexoN2,
    errDatosFinales,
    datosFinales,
    agente,
    errAgente,
    errAnexoN1,
    errAnexoN2,
} from './initialStates/juridicaForm/datosFinales';
import { errPatrimonioJuridica, patrimonioJuridica } from './initialStates/juridicaForm/patrimonio';
import { perfilJuridica, preguntasJuridica, puntajeJuridica } from './initialStates/juridicaForm/juridicaTest';
import {
    errRepresentanteLegal,
    errRepresentantesLegales,
    representanteLegal,
    representantesLegales,
} from './initialStates/juridicaForm/representanteLegal';

const juridicaFormSlice = createSlice({
    name: 'juridicaForm',
    initialState: {
        //test
        preguntasJuridica: preguntasJuridica,
        puntajeJuridica: puntajeJuridica,
        perfilJuridica: perfilJuridica,
        // first step
        aCliente: aCliente,
        errACliente: errACliente,
        masNacionalidades: masNacionalidades,
        errMasNacionalidades: errMasNacionalidades,
        // second step
        representantesLegales: representantesLegales,
        errRepresentantesLegales: errRepresentantesLegales,
        // third step
        patrimonioJuridica: patrimonioJuridica,
        errPatrimonioJuridica: errPatrimonioJuridica,
        // fourth step
        datosFinales: datosFinales,
        agente: agente,
        errAgente: errAgente,
        anexoN1: anexoN1,
        errAnexoN1: errAnexoN1,
        anexoN2: anexoN2,
        errAnexoN2: errAnexoN2,
        errDatosFinales: errDatosFinales,
    },
    reducers: {
        //test
        addTestJuridica: (state, action) => {
            state.preguntasJuridica.push({ pregunta: action.payload.pregunta, respuesta: action.payload.respuesta });
        },
        addPuntajeJuridica: (state, action) => {
            state.puntajeJuridica.puntaje = action.payload.puntaje;
        },
        //first step
        changeJuridicaField: (state, action) => {
            state.aCliente[action.payload.field] = action.payload.content;
        },
        changeJuridicaError: (state, action) => {
            state.errACliente[action.payload.field] = action.payload.content;
        },
        changeNacionalidadesField: (state, action) => {
            state.masNacionalidades[action.payload.index].nacionalidad = action.payload.content;
        },
        changeNacionalidadDireccionField: (state, action) => {
            state.masNacionalidades[action.payload.index].direccion = action.payload.content;
        },
        changeNacionalidadesError: (state, action) => {
            state.errMasNacionalidades[action.payload.index].nacionalidad = action.payload.content;
        },
        changeNacionalidadDireccionError: (state, action) => {
            state.errMasNacionalidades[action.payload.index].direccion = action.payload.content;
        },
        addAnotherNacionalidad: (state) => {
            state.masNacionalidades.push({ nacionalidad: '', direccion: '' });
            state.errMasNacionalidades.push({ nacionalidad: false, direccion: false });
        },
        removeNacionalidad: (state, action) => {
            state.masNacionalidades.splice(action.payload.index, 1);
            state.errMasNacionalidades.push(action.payload.index, 1);
        },
        //second step
        changeRepresentanteLegalField: (state, action) => {
            state.representantesLegales[action.payload.index][action.payload.field] = action.payload.content;
        },
        changeRepresentanteLegalError: (state, action) => {
            state.errRepresentantesLegales[action.payload.index][action.payload.field] = action.payload.content;
        },
        addRepresentanteLegal: (state) => {
            state.representantesLegales.push(representanteLegal);
            state.errRepresentantesLegales.push(errRepresentanteLegal);
        },
        removeRepresentanteLegal: (state, action) => {
            state.representantesLegales.splice(action.payload.index, 1);
            state.errRepresentantesLegales.push(action.payload.index, 1);
        },
        //third step
        changePatrimonioField: (state, action) => {
            state.patrimonioJuridica[action.payload.field] = action.payload.content;
        },
        addRemoveOrigenPatrimonio: (state, action) => {
            if (state.patrimonioJuridica.origen.includes(action.payload.content)) {
                const index = state.patrimonioJuridica.origen.indexOf(action.payload.content);
                state.patrimonioJuridica.origen.splice(index, 1);
            } else {
                state.patrimonioJuridica.origen.push(action.payload.content);
            }
        },
        changePatrimonioError: (state, action) => {
            state.errPatrimonioJuridica[action.payload.field] = action.payload.content;
        },
        //fourth step
        changeDatosFinalesField: (state, action) => {
            state.datosFinales[action.payload.field] = action.payload.content;
        },
        changeAnexoN1Field: (state, action) => {
            state.anexoN1[action.payload.field] = action.payload.content;
        },
        changeErrAnexoN1Field: (state, action) => {
            state.errAnexoN1[action.payload.field] = action.payload.content;
        },
        changeAnexoN2Field: (state, action) => {
            state.anexoN2[action.payload.field] = action.payload.content;
        },
        changeErrAnexoN2Field: (state, action) => {
            state.errAnexoN2[action.payload.field] = action.payload.content;
        },
        changeAgenteField: (state, action) => {
            state.agente[action.payload.field] = action.payload.content;
        },
        changeAgenteError: (state, action) => {
            state.errAgente[action.payload.field] = action.payload.content;
        },
        changeErrDatosFinales: (state, action) => {
            state.errDatosFinales[action.payload.field] = action.payload.content;
        },
        addCedulaFrente: (state, action) => {
            state.aCliente.rutSociedadFrente.push(action.payload.file);
        },
        removeCedulaFrente: (state, action) => {
            state.aCliente.rutSociedadFrente.splice(action.payload.index, 1);
        },
        removeCedulaDorso: (state, action) => {
            state.aCliente.rutSociedadDorso.splice(action.payload.index, 1);
        },
        addCedulaDorso: (state, action) => {
            state.aCliente.rutSociedadDorso.push(action.payload.file);
        },
        addCedulaFrenteRepresentanteLegal: (state, action) => {
            state.representantesLegales[action.payload.index].rutRepresentanteLegalFrente.push(action.payload.file);
        },
        removeCedulaFrenteRepresentanteLegal: (state, action) => {
            state.representantesLegales[action.payload.index].rutRepresentanteLegalFrente.splice(action.payload.i, 1);
        },
        removeCedulaDorsoRepresentanteLegal: (state, action) => {
            state.representantesLegales[action.payload.index].rutRepresentanteLegalDorso.splice(action.payload.i, 1);
        },
        addCedulaDorsoRepresentanteLegal: (state, action) => {
            state.representantesLegales[action.payload.index].rutRepresentanteLegalDorso.push(action.payload.file);
        },
        setPerfilInversionistaJuridica: (state, action) => {
            state.perfilJuridica = action.payload.perfil;
        },
    },
});
export const {
    addTestJuridica,
    addPuntajeJuridica,
    changeJuridicaField,
    changeNacionalidadDireccionField,
    changeNacionalidadesField,
    changeNacionalidadDireccionError,
    addAnotherNacionalidad,
    changeNacionalidadesError,
    changeJuridicaError,
    changeRepresentanteLegalField,
    addRepresentanteLegal,
    removeRepresentanteLegal,
    changeRepresentanteLegalError,
    changePatrimonioField,
    addRemoveOrigenPatrimonio,
    changePatrimonioError,
    changeDatosFinalesField,
    changeAnexoN1Field,
    changeErrAnexoN1Field,
    changeAnexoN2Field,
    changeErrAnexoN2Field,
    removeNacionalidad,
    changeAgenteField,
    changeErrDatosFinales,
    changeAgenteError,
    removeCedulaDorso,
    removeCedulaFrente,
    addCedulaDorso,
    addCedulaFrente,
    removeCedulaDorsoRepresentanteLegal,
    removeCedulaFrenteRepresentanteLegal,
    addCedulaDorsoRepresentanteLegal,
    addCedulaFrenteRepresentanteLegal,
    setPerfilInversionistaJuridica,
} = juridicaFormSlice.actions;
export default juridicaFormSlice.reducer;
