import React from 'react'
import './Stepper.scss'
export const Stepper = ({step}:any) => {
   
      
    return (
        <div className='stepper-container'>
                <ul className="step-wizard-list">
                    <li className={`step-wizard-item ${step===1?'current-item':''}`}>
                        <span className="progress-count">01</span>
                        <span className="progress-label">Perfil de riesgo</span>
                    </li>
                    <li className={`step-wizard-item ${step===2?'current-item':''} `}>
                        <span className="progress-count">02</span>
                        <span className="progress-label">Datos personales</span>
                    </li>
                    <li className={`step-wizard-item ${step===3?'current-item':''}`}>
                        <span className="progress-count">03</span>
                        <span className="progress-label">Contrato</span>
                    </li>
                </ul>
        </div>
    )
}
 
