import React, { useState } from 'react';
import './AClienteForm.scss';
import { paises } from '../../../utils/countries';
import { nacionalidad } from '../../../utils/nacionalidades';

import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalUsPerson } from '../../ModalUsPerson/ModalUsPerson';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../Input/Input';
import {
    changeJuridicaField,
    changeNacionalidadesField,
    changeNacionalidadDireccionField,
    addAnotherNacionalidad,
    changeNacionalidadDireccionError,
    changeNacionalidadesError,
    changeJuridicaError,
    removeNacionalidad,
    removeCedulaFrente,
    removeCedulaDorso,
} from '../../../redux/reducers/juridicaForm';
import iconClose from '../../../assets/svg/icon-close.svg';
import { ModalCedula } from '../../ModalCedulaACliente/ModalCedula';

const popoverPoliticaj =
    '“Son los chilenos o extranjeros que desempeñen o hayan desempeñado funciones públicas destacadas en Chile o en el extranjero, hasta un año de finalizado el ejercicio de las mismas. Se incluyen en esta categoría los jefes de estado o de un gobierno, políticos de alta jerarquía (entre ellos, a los miembros de mesas directivas de partidos políticos), funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales; en todos estos casos comprende también a sus cónyuges, sus parientes hasta el segundo grado de consanguinidad (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)) y a las personas naturales con las que hayan celebrado un pacto de actuación conjunta mediante el cual tengan poder de voto suficiente , para influir en sociedades constituidas en Chile.”';

export const AClienteForm = ({ setFormStep }: any) => {
    const [showModalUsPerson, setShowModalUsPerson] = useState(false);
    const { type }: any = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const aClienteState = useSelector((state: any) => state.juridicaForm.aCliente);
    const errAClienteState = useSelector((state: any) => state.juridicaForm.errACliente);
    const masNacionalidades = useSelector((state: any) => state.juridicaForm.masNacionalidades);
    const errMasNacionalidades = useSelector((state: any) => state.juridicaForm.errMasNacionalidades);
    const [showPopoverPoliticaj, setshowPopoverPoliticaj] = useState(false);
    const [showModalCedula, setShowModalCedula] = useState(false);

    const validateAClienteData = () => {
        let isValid = true;
        Object.entries(aClienteState).map((item: any) => {
            if (item[0] !== 'dpto') {
                dispatch(changeJuridicaError({ field: item[0], content: item[1] === '' }));
                if (item[1] === '') isValid = false;
            }
            return null;
        });
        if (aClienteState.domicilioFiscal) {
            Object.entries(masNacionalidades).map((item: any, i: any) => {
                dispatch(changeNacionalidadesError({ index: i, content: item[1].nacionalidad === '' }));
                dispatch(changeNacionalidadDireccionError({ index: i, content: item[1].direccion === '' }));
                if (item[1].nacionalidad === '' || item[1].direccion === '') isValid = false;
                return null;
            });
        }

        return isValid;
    };
    return (
        <>
            <form id="form" action="">
                <div className="row">
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-9 col-md-9 col-sm-9 col-xs-5 input-box '}
                        label={'Rut/Pasaporte'}
                        state={aClienteState.rutPasaporte}
                        field={'rutPasaporte'}
                        error={errAClienteState.rutPasaporte}
                        reducer={changeJuridicaField}
                    />
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-9 col-md-9 col-sm-9 col-xs-5 input-box '}
                        label={'Nombre o Razón Social'}
                        state={aClienteState.nombreRazonSocial}
                        field={'nombreRazonSocial'}
                        error={errAClienteState.nombreRazonSocial}
                        reducer={changeJuridicaField}
                    />
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            {errAClienteState.paisConstitucion ? <span className="error-color">*</span> : ''}
                            País de Constitución
                        </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <select
                            className={`input-select input-activo ${
                                errAClienteState.paisConstitucion ? 'input-error' : ''
                            }`}
                            onChange={(e) =>
                                dispatch(changeJuridicaField({ field: 'paisConstitucion', content: e.target.value }))
                            }
                        >
                            {paises.map((pais, i) => {
                                return (
                                    <option key={i} value={pais.name}>
                                        {pais.name}
                                    </option>
                                );
                            })}
                        </select>
                        {errAClienteState.paisNacimiento && (
                            <p className="error-text error-color">*Campo obligatorio</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box">
                        <label>
                            ¿Eres US person?
                            <div className=" question-mark d-inline">
                                <FontAwesomeIcon
                                    className="ml-2"
                                    icon={faQuestionCircle}
                                    onClick={() => setShowModalUsPerson(true)}
                                />
                            </div>
                            <ModalUsPerson show={showModalUsPerson} setShow={setShowModalUsPerson} />
                        </label>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 d-flex justify-content-around align-items-center ">
                        <span className="input-radio-box">
                            <input
                                checked={aClienteState.usPerson}
                                className="input-radio"
                                type="radio"
                                name="USPerson"
                                value="Si"
                                onChange={(e) => dispatch(changeJuridicaField({ field: 'usPerson', content: true }))}
                            />{' '}
                            Si
                        </span>
                        <span className="input-radio-box">
                            <input
                                checked={!aClienteState.usPerson}
                                className="input-radio"
                                type="radio"
                                name="USPerson"
                                value="No"
                                onChange={(e) => dispatch(changeJuridicaField({ field: 'usPerson', content: false }))}
                            />{' '}
                            No
                        </span>
                    </div>
                    <Input
                        label={'Teléfono'}
                        field={'telefono'}
                        state={aClienteState.telefono}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box'}
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box'}
                        placeholder={'+56 9 74851205'}
                        error={errAClienteState.telefono}
                        reducer={changeJuridicaField}
                    />
                    <Input
                        label={'Email'}
                        field={'email'}
                        state={aClienteState.email}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box'}
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box'}
                        placeholder={'email@gmail.com'}
                        error={errAClienteState.email}
                        reducer={changeJuridicaField}
                    />

                    <div className="col-12">
                        <div className="row">
                            <Input
                                label={'Dirección'}
                                field={'direccion'}
                                state={aClienteState.direccion}
                                inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box'}
                                labelClass={'col-lg-2 col-md-2 col-sm-3 col-xs-6 input-box'}
                                placeholder={'Dirección'}
                                error={errAClienteState.direccion}
                                reducer={changeJuridicaField}
                            />
                            <div className="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                                <div className="row">
                                    <Input
                                        label={'Número'}
                                        field={'numero'}
                                        state={aClienteState.numero}
                                        labelClass={'col-lg-2 col-md-2 col-sm-3 col-xs-6 input-box'}
                                        inputClass={'col-lg-4 col-md-4 col-sm-3 col-xs-6 input-box'}
                                        placeholder={'0000'}
                                        error={errAClienteState.numero}
                                        reducer={changeJuridicaField}
                                    />
                                    <Input
                                        label={'Dpto'}
                                        field={'dpto'}
                                        state={aClienteState.dpto}
                                        labelClass={'col-lg-2 col-md-2 col-sm-3 col-xs-6 input-box'}
                                        inputClass={'col-lg-4 col-md-4 col-sm-3 col-xs-6 input-box'}
                                        placeholder={'3 A'}
                                        error={errAClienteState.dpto}
                                        reducer={changeJuridicaField}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Input
                        labelClass={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}
                        inputClass={'col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box'}
                        label={'Ciudad'}
                        state={aClienteState.ciudad}
                        field={'ciudad'}
                        error={errAClienteState.ciudad}
                        reducer={changeJuridicaField}
                        placeholder={'Ciudad'}
                    />

                    <Input
                        labelClass={'col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box'}
                        inputClass={'col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box'}
                        label={'Comuna'}
                        state={aClienteState.comuna}
                        field={'comuna'}
                        error={errAClienteState.comuna}
                        reducer={changeJuridicaField}
                        placeholder={'Comuna'}
                    />
                    <div className="col-12">
                        <div className={`row d-flex ${aClienteState.domicilioFiscal ? 'justify-content-end' : ''}`}>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                                <label>Domicilio fiscal en otro pais</label>
                            </div>
                            <div
                                className={`col-lg-2 col-md-2 col-sm-2 col-xs-6 d-flex flex-column justify-content-around align-items-left mt-2 ${
                                    aClienteState.domicilioFiscal ? 'separation-bar-right:' : ''
                                }`}
                            >
                                <span className="input-radio-box">
                                    <input
                                        checked={aClienteState.domicilioFiscal}
                                        className="input-radio"
                                        type="radio"
                                        name="domicilioFiscal"
                                        value="Si"
                                        onChange={() =>
                                            dispatch(
                                                changeJuridicaField({
                                                    field: 'domicilioFiscal',
                                                    content: true,
                                                })
                                            )
                                        }
                                    />
                                    Si
                                </span>
                                <span className="input-radio-box">
                                    <input
                                        checked={!aClienteState.domicilioFiscal}
                                        className="input-radio"
                                        type="radio"
                                        name="domicilioFiscal"
                                        value="No"
                                        onChange={() =>
                                            dispatch(
                                                changeJuridicaField({
                                                    field: 'domicilioFiscal',
                                                    content: false,
                                                })
                                            )
                                        }
                                    />
                                    No
                                </span>
                            </div>
                            {aClienteState.domicilioFiscal &&
                                masNacionalidades.map((c: any, i: any) => {
                                    return (
                                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mt-3" key={i}>
                                            {i !== 0 && i === masNacionalidades.length - 1 ? (
                                                <div className={`row`}>
                                                    <div className="col-12 pru p-2">
                                                        <img
                                                            src={iconClose}
                                                            onClick={() => {
                                                                dispatch(removeNacionalidad({ index: i }));
                                                            }}
                                                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                                            alt="icon-close"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="row">
                                                        <div
                                                            className={`error-label-APNF col-lg-3 col-md-3 col-sm-3 col-xs-3 input-box`}
                                                        >
                                                            <label>
                                                                {errMasNacionalidades[i].nacionalidad ? (
                                                                    <span className="error-color">*</span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                ¿Cuál?
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 d-flex justify-content-center align-items-center">
                                                            <select
                                                                className={`input-select input-activo ${
                                                                    errMasNacionalidades[i].nacionalidad
                                                                        ? 'input-error'
                                                                        : ''
                                                                }`}
                                                                onChange={(e) =>
                                                                    dispatch(
                                                                        changeNacionalidadesField({
                                                                            index: i,
                                                                            content: e.target.value,
                                                                        })
                                                                    )
                                                                }
                                                            >
                                                                {nacionalidad.map((nacionali, i) => {
                                                                    return (
                                                                        <option key={i} value={nacionali.name}>
                                                                            {nacionali.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                            {errMasNacionalidades[i].nacionalidad && (
                                                                <p className="error-text-APNF error-color">
                                                                    *Campo obligatorio
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`col-lg-3 col-md-3 col-sm-3 col-xs-3 input-box`}
                                                        >
                                                            <label>
                                                                {errMasNacionalidades[i].direccion ? (
                                                                    <span className="error-color">*</span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                Dirección
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 d-flex justify-content-center align-items-center">
                                                            <input
                                                                className={`input-activo ${
                                                                    errMasNacionalidades[i].direccion
                                                                        ? 'input-error'
                                                                        : ''
                                                                }`}
                                                                placeholder="Dirección"
                                                                value={masNacionalidades[i].direccion}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    dispatch(
                                                                        changeNacionalidadDireccionField({
                                                                            index: i,
                                                                            content: e.target.value,
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                            {errMasNacionalidades[i].direccion && (
                                                                <p className="error-text-APNF error-color">
                                                                    *Campo obligatorio
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            {aClienteState.domicilioFiscal && (
                                <div className="col-12 mt-2 mb-2">
                                    <div className="row d-flex justify-content-end">
                                        <div className="error-label-APNF col-lg-4 col-sm-6">
                                            <div
                                                className={`btn btn-avante-secondary-white btn-home d-flex align-items-center justify-content-center`}
                                                onClick={() => dispatch(addAnotherNacionalidad())}
                                            >
                                                Agregar otro domicilio
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <Input
                                label={'Giro de la sociedad'}
                                field={'giroSociedad'}
                                state={aClienteState.giroSociedad}
                                labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box'}
                                inputClass={'col-lg-9 col-md-9 col-sm-9 col-xs-6 input-box'}
                                error={errAClienteState.giroSociedad}
                                reducer={changeJuridicaField}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 input-box ">
                                <label>
                                    Los socios/accionistas de la Persona Jurídica con una participación igual o superior
                                    al 10%, califican como persona políticamente expuesta &nbsp;
                                    <FontAwesomeIcon
                                        style={{ color: '#233746' }}
                                        icon={faQuestionCircle}
                                        onMouseEnter={() => setshowPopoverPoliticaj(true)}
                                        onMouseLeave={() => setshowPopoverPoliticaj(false)}
                                    />
                                </label>
                                <div className={`popover-politicoo ${!showPopoverPoliticaj ? 'hide' : ''}`}>
                                    {popoverPoliticaj}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="col-12 d-flex flex-column justify-content-around align-items-left mt-2">
                                    <span className="input-radio-box">
                                        <input
                                            checked={aClienteState.politicamenteExpuesta}
                                            className="input-radio"
                                            type="radio"
                                            name="pExpuesta"
                                            value="Si"
                                            onChange={() =>
                                                dispatch(
                                                    changeJuridicaField({
                                                        field: 'politicamenteExpuesta',
                                                        content: true,
                                                    })
                                                )
                                            }
                                        />{' '}
                                        Si
                                    </span>
                                    <span className="input-radio-box">
                                        <input
                                            checked={!aClienteState.politicamenteExpuesta}
                                            className="input-radio"
                                            type="radio"
                                            name="pExpuesta"
                                            value="No"
                                            onChange={() =>
                                                dispatch(
                                                    changeJuridicaField({
                                                        field: 'politicamenteExpuesta',
                                                        content: false,
                                                    })
                                                )
                                            }
                                        />{' '}
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="col-12 cedula-title">
                                <span>
                                    {errAClienteState.rutSociedadDorso || errAClienteState.rutSociedadFrente ? (
                                        <span className="error-color">*</span>
                                    ) : (
                                        ''
                                    )}
                                    Rut de la sociedad
                                </span>
                                {(errAClienteState.rutSociedadDorso || errAClienteState.rutSociedadFrente) && (
                                    <p className="error-text error-color mb-3">*Campo obligatorio</p>
                                )}
                            </div>
                            <div className="col-lg-8 col-sm-6 cedula-instruccion">
                                <span>
                                    Sube un imagen de tu Cédula de identidad por ambos lados. (Formato JPG, PNG ó PDF
                                    con un máximo de 9MB)
                                </span>
                            </div>
                            <div className="col-lg-4 col-sm-6 cedula-button">
                                <div
                                    className="btn btn-avante-secondary btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => setShowModalCedula(true)}
                                >
                                    Subir Cédula
                                    <ModalCedula show={showModalCedula} setShow={setShowModalCedula} />
                                </div>
                            </div>
                            {aClienteState.rutSociedadDorso.length !== 0 ||
                                (aClienteState.rutSociedadFrente.length !== 0 && (
                                    <span className="col-12 cedula-sub-title">Documentos Adjuntos:</span>
                                ))}
                            <div className="col-lg-8 col-sm-8 cedula-button">
                                {aClienteState.rutSociedadFrente.map((el: any, i: number) => {
                                    return (
                                        <div className="row">
                                            <div key={i} className="col-8 d-flex text-center ">
                                                <span className="mr-3">{el.path} </span>
                                            </div>
                                            <div className="col-4 d-flex align-items-center justify-content-center">
                                                <img
                                                    style={{ width: '16px', cursor: 'pointer' }}
                                                    src={iconClose}
                                                    alt=""
                                                    onClick={() => dispatch(removeCedulaFrente({ index: i }))}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                {aClienteState.rutSociedadDorso.map((el: any, i: number) => {
                                    return (
                                        <div className="row">
                                            <div key={i} className="col-8 d-flex text-center ">
                                                <span className="mr-3">{el.path} </span>
                                            </div>
                                            <div className="col-4 d-flex align-items-center justify-content-center">
                                                <img
                                                    style={{ width: '16px', cursor: 'pointer' }}
                                                    src={iconClose}
                                                    alt=""
                                                    onClick={() => dispatch(removeCedulaDorso({ index: i }))}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5 mb-3">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                                <div
                                    className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => history.push(`/cuestionario/${type}`)}
                                >
                                    Anterior
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                                <div
                                    className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        if (validateAClienteData()) {
                                            setFormStep(1);
                                        }
                                    }}
                                >
                                    Continuar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
