import { createSlice } from '@reduxjs/toolkit';
import { aLaborales, errALaborales, ocupacion, origenMonetario } from './initialStates/naturalForm/aLaborales';
import { perfilNatural, preguntasNatural, puntajeNatural } from './initialStates/naturalForm/naturalTest';
import {
    aPersonales,
    errAPersonales,
    representanteLegal,
    errRepresentanteLegal,
    representantesLegales,
    masNacionalidades,
    errMasNacionalidades,
    errRepresentantesLegales,
    errDireccionDomicilioFiscal,
    direccionDomicilioFiscal,
} from './initialStates/naturalForm/aPersonales';
import {
    agente,
    anexoAData,
    anexoAErrors,
    anexoBData,
    anexoBErrors,
    contacto,
    contactosAdicionales,
    datosFinales,
    errAgente,
    errContacto,
    errContactosAdicionales,
    errDatosFinales,
} from './initialStates/naturalForm/datosFinales';
const naturalFormSlice = createSlice({
    name: 'naturalForm',
    initialState: {
        //test
        preguntasNatural: preguntasNatural,
        puntajeNatural: puntajeNatural,
        perfilNatural: perfilNatural,
        // first step
        aPersonales: aPersonales,
        representantesLegales: representantesLegales,
        direccionDomicilioFiscal: direccionDomicilioFiscal,
        errDireccionDomicilioFiscal: errDireccionDomicilioFiscal,
        errRepresentantesLegales: errRepresentantesLegales,
        masNacionalidades: masNacionalidades,
        errMasNacionalidades: errMasNacionalidades,
        errAPersonales: errAPersonales,
        // second step
        aLaborales: aLaborales,
        errALaborales: errALaborales,
        ocupacion: ocupacion,
        origenMonetario: origenMonetario,
        //third step
        datosFinales: datosFinales,
        errDatosFinales: errDatosFinales,
        contactosAdicionales: contactosAdicionales,
        errContactosAdicionales: errContactosAdicionales,
        agente: agente,
        errAgente: errAgente,
        // anexo B
        anexoBData: anexoBData,
        anexoBErrors: anexoBErrors,
        // anexo A
        anexoAData: anexoAData,
        anexoAErrors: anexoAErrors,
    },
    reducers: {
        //test
        addTestNatural: (state, action) => {
            state.preguntasNatural.push({ pregunta: action.payload.pregunta, respuesta: action.payload.respuesta });
        },
        addPuntajeNatural: (state, action) => {
            state.puntajeNatural.puntaje = action.payload.puntaje;
        },
        //first step
        changePersonalField: (state, action) => {
            state.aPersonales[action.payload.field] = action.payload.content;
        },
        changeNacionalidadesField: (state, action) => {
            state.masNacionalidades[action.payload.index].nacionalidad = action.payload.content;
        },
        changeNacionalidadDireccionField: (state, action) => {
            state.masNacionalidades[action.payload.index].direccion = action.payload.content;
        },
        addAnotherNacionalidad: (state) => {
            state.masNacionalidades.push({ nacionalidad: '', direccion: '' });
            state.errMasNacionalidades.push({ nacionalidad: false, direccion: false });
        },
        addRepresentanteLegal: (state) => {
            state.representantesLegales.push(representanteLegal);
            state.errRepresentantesLegales.push(errRepresentanteLegal);
        },
        removeRepresentanteLegal: (state, action) => {
            state.representantesLegales.splice(action.payload.index, 1);
            state.errRepresentantesLegales.push(action.payload.index, 1);
        },
        changeRepresentanteLegalField: (state, action) => {
            state.representantesLegales[action.payload.index][action.payload.field] = action.payload.content;
        },
        changeRepresentanteLegalError: (state, action) => {
            state.errRepresentantesLegales[action.payload.index][action.payload.field] = action.payload.content;
        },
        addRepresentanteLegalDoc: (state, action) => {
            state.aPersonales.representantesLegales[action.payload.index].documentos.push(action.payload.file);
        },
        addCedulaFrente: (state, action) => {
            state.aPersonales.cedulaFrente.push(action.payload.file);
        },
        addDocumentosRepresentaneLegal: (state, action) => {
            state.representantesLegales[action.payload.index].documentos.push(action.payload.file);
        },
        removeDocumentosRepresentaneLegal: (state, action) => {
            state.representantesLegales[action.payload.repIndex].documentos.splice(action.payload.docIndex, 1);
        },
        removeCedulaFrente: (state, action) => {
            state.aPersonales.cedulaFrente.splice(action.payload.index, 1);
        },
        removeCedulaDorso: (state, action) => {
            state.aPersonales.cedulaDorso.splice(action.payload.index, 1);
        },
        addCedulaDorso: (state, action) => {
            state.aPersonales.cedulaDorso.push(action.payload.file);
        },
        changePersonalError: (state, action) => {
            state.errAPersonales[action.payload.field] = action.payload.content;
        },
        changeNacionalidadesError: (state, action) => {
            state.errMasNacionalidades[action.payload.index].nacionalidad = action.payload.content;
        },
        changeNacionalidadDireccionError: (state, action) => {
            state.errMasNacionalidades[action.payload.index].direccion = action.payload.content;
        },
        changeDireccionDomicilioFiscal: (state, action) => {
            state.direccionDomicilioFiscal = action.payload.content;
        },
        changeErrDireccionDomicilioFiscal: (state, action) => {
            state.errDireccionDomicilioFiscal = action.payload.content;
        },
        // second step
        changeLaboralField: (state, action) => {
            state.aLaborales[action.payload.field] = action.payload.content;
        },
        changeOcupacion: (state, action) => {
            if (state.ocupacion.includes(action.payload.item)) {
                const index = state.ocupacion.indexOf(action.payload.item);
                state.ocupacion.splice(index, 1);
            } else {
                state.ocupacion.push(action.payload.item);
            }
        },
        changeOrigenMonetario: (state, action) => {
            if (state.origenMonetario.includes(action.payload.item)) {
                const index = state.origenMonetario.indexOf(action.payload.item);
                state.origenMonetario.splice(index, 1);
            } else {
                state.origenMonetario.push(action.payload.item);
            }
        },
        changeLaboralError: (state, action) => {
            state.errALaborales[action.payload.field] = action.payload.content;
        },
        changeDatosFinalesField: (state, action) => {
            state.datosFinales[action.payload.field] = action.payload.content;
        },
        addContactoAdicional: (state) => {
            state.contactosAdicionales.push(contacto);
            state.errContactosAdicionales.push(errContacto);
        },
        changeContactoAdicionalField: (state, action) => {
            state.contactosAdicionales[action.payload.index][action.payload.field] = action.payload.content;
        },
        changeContactoAdicionalError: (state, action) => {
            state.errContactosAdicionales[action.payload.index][action.payload.field] = action.payload.content;
        },
        changeAnexoBData: (state, action) => {
            state.anexoBData[action.payload.field] = action.payload.content;
        },
        changeAnexoBError: (state, action) => {
            state.anexoBErrors[action.payload.field] = action.payload.content;
        },
        changeAnexoAData: (state, action) => {
            state.anexoAData[action.payload.field] = action.payload.content;
        },
        changeAnexoAError: (state, action) => {
            state.anexoAErrors[action.payload.field] = action.payload.content;
        },
        changeAgenteField: (state, action) => {
            state.agente[action.payload.field] = action.payload.content;
        },
        changeAgenteError: (state, action) => {
            state.errAgente[action.payload.field] = action.payload.content;
        },
        removeNacionalidad: (state, action) => {
            state.masNacionalidades.splice(action.payload.index, 1);
            state.errMasNacionalidades.push(action.payload.index, 1);
        },
        changeErrDatosFinales: (state, action) => {
            state.errDatosFinales[action.payload.field] = action.payload.content;
        },
        removeContactoAdicional: (state, action) => {
            state.contactosAdicionales.splice(action.payload.index, 1);
            state.errContactosAdicionales.push(action.payload.index, 1);
        },
        setPerfilInversionistaNatural: (state, action) => {
            state.perfilNatural = action.payload.perfil;
        },
    },
});
export const {
    addTestNatural,
    addPuntajeNatural,
    changePersonalField,
    changeNacionalidadesField,
    changeNacionalidadDireccionField,
    addAnotherNacionalidad,
    addRepresentanteLegal,
    changeRepresentanteLegalField,
    addRepresentanteLegalDoc,
    addCedulaFrente,
    addCedulaDorso,
    changePersonalError,
    changeNacionalidadesError,
    changeNacionalidadDireccionError,
    changeRepresentanteLegalError,
    removeRepresentanteLegal,
    changeDireccionDomicilioFiscal,
    changeErrDireccionDomicilioFiscal,
    changeLaboralField,
    changeLaboralError,
    changeOcupacion,
    changeOrigenMonetario,
    changeDatosFinalesField,
    addContactoAdicional,
    changeContactoAdicionalField,
    changeAnexoBData,
    addCheckBoxesAnexoB,
    changeAnexoBError,
    addCheckBoxesAnexoA,
    changeAnexoAData,
    changeAnexoAError,
    changeAgenteField,
    changeAgenteError,
    removeNacionalidad,
    changeErrDatosFinales,
    removeContactoAdicional,
    changeContactoAdicionalError,
    removeCedulaFrente,
    removeCedulaDorso,
    addDocumentosRepresentaneLegal,
    removeDocumentosRepresentaneLegal,
    setPerfilInversionistaNatural,
} = naturalFormSlice.actions;
export default naturalFormSlice.reducer;
