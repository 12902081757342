import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeRepresentanteLegalError } from '../../redux/reducers/juridicaForm';
import {
    changeErrDireccionDomicilioFiscal,
    changeLaboralError,
    changeNacionalidadDireccionError,
} from '../../redux/reducers/naturalForm';
import { changeNacionalidadesError, changePersonalError } from '../../redux/reducers/naturalForm';
import { ALaboralesNaturalForm } from './ALaboralesNaturalForm/ALaboralesNaturalForm';
import { APersonalesNaturalForm } from './APersonalesNaturalForm/APersonalesNaturalForm';
import { DatosFinalesNaturalForm } from './DatosFinalesNaturalForm/DatosFinalesNaturalForm';
import './NaturalForm.scss';

export const NaturalForm = () => {
    const [formStep, setFormStep] = useState(0);
    const dispatch = useDispatch();
    const aPersonalesState = useSelector((state: any) => state.naturalForm.aPersonales);
    const masNacionalidades = useSelector((state: any) => state.naturalForm.masNacionalidades);
    const direccionDomicilioFiscal = useSelector((state: any) => state.naturalForm.direccionDomicilioFiscal);
    const representantesLegales = useSelector((state: any) => state.naturalForm.representantesLegales);
    const ocupacion = useSelector((state: any) => state.naturalForm.ocupacion);
    const aLaborales = useSelector((state: any) => state.naturalForm.aLaborales);
    const origenMonetario = useSelector((state: any) => state.naturalForm.origenMonetario);

    const validateAPersonalesData = () => {
        let isValid = true;
        Object.entries(aPersonalesState).map((item: any) => {
            dispatch(changePersonalError({ field: item[0], content: item[1] === '' }));
            if (item[1] === '') isValid = false;
            return null;
        });
        if (aPersonalesState.otraNacionalidad) {
            Object.entries(masNacionalidades).map((item: any, i: any) => {
                dispatch(changeNacionalidadesError({ index: i, content: item[1].nacionalidad === '' }));
                dispatch(changeNacionalidadDireccionError({ index: i, content: item[1].direccion === '' }));
                if (item[1].nacionalidad === '' || item[1].direccion === '') isValid = false;
                return null;
            });
        }
        if (aPersonalesState.domicilioFiscal) {
            dispatch(
                changeErrDireccionDomicilioFiscal({
                    content: direccionDomicilioFiscal === '',
                })
            );
            if (direccionDomicilioFiscal === '') isValid = false;
        }
        if (aPersonalesState.representanteLegalRadio) {
            representantesLegales.map((item: any, i: any) => {
                Object.entries(item).map((key: any, keyI: any) => {
                    dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: key[1] === '' }));
                    if (key[0] === 'otro') {
                        dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: false }));
                    }
                    if (key[1] === '' && key[0] !== 'otro') isValid = false;
                    return null;
                });
                return null;
            });
        }

        return isValid;
    };

    const validateALaborales = () => {
        dispatch(changeLaboralError({ field: 'profesion', content: false }));
        dispatch(changeLaboralError({ field: 'nombreEmpresa', content: false }));
        dispatch(changeLaboralError({ field: 'ocupacion', content: false }));
        dispatch(changeLaboralError({ field: 'otroOrigen', content: false }));
        dispatch(changeLaboralError({ field: 'otraOcupacion', content: false }));

        dispatch(changeLaboralError({ field: 'ocupacion', content: ocupacion.length === 0 }));

        Object.entries(aLaborales).map((item: any) => {
            if (ocupacion.includes('Empresario/a') || ocupacion.includes('Empleado/a')) {
                dispatch(changeLaboralError({ field: item[0], content: item[1] === '' }));
            } else {
                if (item[0] === 'otraOcupacion' && ocupacion.includes('Otro')) {
                    dispatch(changeLaboralError({ field: 'otraOcupacion', content: item[1] === '' }));
                }
            }
            return null;
        });

        dispatch(changeLaboralError({ field: 'origenMonetario', content: origenMonetario.length === 0 }));
        return returnValidation();
    };

    const returnValidation = () => {
        let isValidFieldOtroOrigen = true;
        let isValidFieldOtraOcupacion = true;
        let isValidFieldEmpresa = true;
        let isValidFieldProfesion = true;
        let isValidList = true;

        Object.entries(aLaborales).map((item: any) => {
            if (origenMonetario.includes('Otros') && item[0] === 'otroOrigen') {
                isValidFieldOtroOrigen = item[1] !== '';
                dispatch(changeLaboralError({ field: 'otroOrigen', content: item[1] === '' }));
            }
            if (item[0] !== 'otroOrigen' && item[0] !== 'otraOcupacion' && item[0] !== 'ingresoMensual') {
                if (ocupacion.includes('Empresario/a') || ocupacion.includes('Empleado/a')) {
                    if (item[0] === 'profesion') isValidFieldProfesion = item[1] !== '';
                    if (item[0] === 'nombreEmpresa') isValidFieldEmpresa = item[1] !== '';
                    return null;
                }
            }
            if (item[0] === 'otraOcupacion' && ocupacion.includes('Otro')) {
                isValidFieldOtraOcupacion = item[1] !== '';
            }
            return null;
        });
        isValidList = ocupacion.length !== 0 && origenMonetario.length !== 0;

        return (
            isValidFieldEmpresa &&
            isValidFieldProfesion &&
            isValidFieldOtroOrigen &&
            isValidList &&
            isValidFieldOtraOcupacion
        );
    };
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [formStep]);
    return (
        <div className="natural-form-page">
            <div className="row natural-form-box d-flex justify-content-center">
                <div className="col-xl-12">
                    <div className="row d-flex justify-content-center">
                        <div className="col-xl-8 col-sm-12">
                            <div className="row form-stepper">
                                <div
                                    className={`d-flex justify-content-center form-stepper-item ${
                                        formStep === 0 ? 'form-stepper-item-active' : ''
                                    }`}
                                    onClick={() => {
                                        setFormStep(0);
                                    }}
                                >
                                    Antecedentes personales
                                </div>
                                <div className="form-stepper-item">|</div>
                                <div
                                    className={`form-stepper-item ${formStep === 1 ? 'form-stepper-item-active' : ''} `}
                                    onClick={() => {
                                        if (validateAPersonalesData()) {
                                            setFormStep(1);
                                        }
                                    }}
                                >
                                    Antecedentes laborales
                                </div>
                                <div className="form-stepper-item">|</div>
                                <div
                                    className={`form-stepper-item ${formStep === 2 ? 'form-stepper-item-active' : ''} `}
                                    onClick={() => {
                                        if (validateAPersonalesData() && validateALaborales()) {
                                            setFormStep(3);
                                        }
                                    }}
                                >
                                    Datos finales
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                    <div className="row d-flex justify-content-center">
                        <div className="col-11">
                            {formStep === 0 ? (
                                <APersonalesNaturalForm setFormStep={setFormStep} />
                            ) : formStep === 1 ? (
                                <ALaboralesNaturalForm
                                    setFormStep={setFormStep}
                                    validateALaborales={validateALaborales}
                                />
                            ) : (
                                <DatosFinalesNaturalForm setFormStep={setFormStep} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
