export const aLaborales = {
    ingresoMensual: 'Menos de $1.000.000',
    profesion: '',
    nombreEmpresa: '',
    otraOcupacion:'',
    otroOrigen:''
};

export const ocupacion = [];

export const origenMonetario = [];

export const errALaborales = {
    ocupacion: false,
    profesion: false,
    nombreEmpresa: false,
    ingresoMensual: false,
    origenMonetario: false,
    otraOcupacion:false,
    otroOrigen:false

};
