import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addPuntajeJuridica, setPerfilInversionistaJuridica } from '../../redux/reducers/juridicaForm';
import { addPuntajeNatural, setPerfilInversionistaNatural } from '../../redux/reducers/naturalForm';
import { Boton } from '../Boton/Boton';
import Layout from '../Layout/Layout';
import Pregunta from '../Pregunta/Pregunta';
import { ResultadoCuestionario } from '../ResultadoCuestionario/ResultadoCuestionario';
import { Stepper } from '../Stepper/Stepper';
import './Cuestionario.scss';

const preguntasNatural = [
    {
        pregunta: 'Seleccione su tramo de edad:',
        respuestas: [
            {
                respuesta: 'A. Más de 60 años.',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre 40 y 60 años.',
                puntaje: 3,
            },
            {
                respuesta: 'C. Menos de 40 años.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Cuánto de sus ingresos/ahorros estaría dispuesto a invertir?',
        respuestas: [
            {
                respuesta: 'A. Menos del 30%.',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre 30% y 60%.',
                puntaje: 3,
            },
            {
                respuesta: 'C. Más del 60%.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Cómo generó el dinero que desea invertir?',
        respuestas: [
            {
                respuesta: 'A. Básicamente, mediante mi trabajo.',
                puntaje: 1,
            },
            {
                respuesta: 'B. Mediante mi trabajo, más algunos ahorros.',
                puntaje: 3,
            },
            {
                respuesta: 'C. De otras rentas no relacionadas con mi trabajo.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta:
            'De sus ingresos mensuales, ¿qué porcentaje aproximado destina a gastos recurrentes y al pago de obligaciones financieras?',
        respuestas: [
            {
                respuesta: 'A. Mayor al 60%',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre un 30% y el 60%',
                puntaje: 3,
            },
            {
                respuesta: 'C. Menos del 30%',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Cuál es el objetivo principal de su inversión?',
        respuestas: [
            {
                respuesta:
                    'A. Asegurar y mantener mi capital. No quiero arriesgar mi inversión inicial. No me siento cómodo con fluctuaciones de valor en el corto plazo. ',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Aumentar mi patrimonio, ojalá con retornos estables. Estoy dispuesto a aceptar pequeñas fluctuaciones de valor en el mediano plazo, incluyendo posibles pérdidas menores en mi inversión inicial.  ',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Generar los mayores rendimientos posibles con el capital invertido, sin importar los riesgos. Para ello estoy dispuesto a aceptar fluctuaciones negativas de valor, incluyendo la posible pérdida de mi inversión inicial.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿A qué plazo le gustaría invertir?',
        respuestas: [
            {
                respuesta: 'A. Hasta 1 año.',
                puntaje: 1,
            },
            {
                respuesta: 'B. De 1 a 3 años.',
                puntaje: 3,
            },
            {
                respuesta: 'C. Más de 3 años.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Qué frase describe mejor su grado de conocimiento y experiencia en inversiones?',
        respuestas: [
            {
                respuesta: 'A. Mi conocimiento en inversiones es bajo. Nunca he participado en el mercado de valores.',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Mi nivel de conocimiento en inversiones es medio. Ocasionalmente participo en el mercado de valores, pero para ello necesito asesoría especializada.',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Mi grado de conocimiento en inversiones es alto. Participo activamente en el mercado de valores y me siento cómodo tomando mis propias decisiones de inversión, sin que para ello requiera ser asesorado.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: 'La palabra riesgo para usted significa:',
        respuestas: [
            {
                respuesta: 'A. Alerta',
                puntaje: 1,
            },
            {
                respuesta: 'B. Inseguridad',
                puntaje: 3,
            },
            {
                respuesta: 'C. Oportunidad',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Qué frase lo identifica mejor con respecto a las inversiones?',
        respuestas: [
            {
                respuesta:
                    'A. Invertiría sólo en instrumentos de bajo riesgo, aunque su rentabilidad sea baja. Si mi inversión comienza a perder valor, vendería inmediatamente.',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Invertiría una parte en instrumentos de bajo riesgo, y otra en instrumentos que, aunque más riesgosos, tienen mayor rentabilidad. Si mi inversión comienza a perder valor, consultaría a un experto.',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Invertiría todo en instrumentos de alto rendimiento, para maximizar mi ganancia, aun cuando esto signifique asumir mayores riesgos. Si mi inversión comienza a perder valor, asumiría las pérdidas en el corto plazo, y esperaría obtener ganancias en el largo plazo.',
                puntaje: 5,
            },
        ],
    },
];
const perfiles = [
    {
        titulo: 'conservador',
        texto: ' Valoras la seguridad en tus ganancias.',
    },
    {
        titulo: 'moderado',
        texto: 'Toleras cierto nivel de riesgo, a cambio de obtener algo de rentabilidad.',
    },
    {
        titulo: 'agresivo',
        texto: 'Asumes riesgos en los mercados y tienes altas expectativas de rentabilidad.',
    },
];
const preguntasJuridica = [
    {
        pregunta: '¿Cuál es el horizonte de tiempo de su inversión?',
        respuestas: [
            {
                respuesta: 'A. Hasta 1 año.',
                puntaje: 1,
            },
            {
                respuesta: 'B. De 1 a 3 años.',
                puntaje: 3,
            },
            {
                respuesta: 'C. Más de 3 años.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: 'El monto que dispondrá para su inversión es:',
        respuestas: [
            {
                respuesta: 'A. Menor al 30% de su patrimonio líquido',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre el 30% y el 60% de su patrimonio líquido',
                puntaje: 3,
            },
            {
                respuesta: 'C. Mayor al 60% de su patrimonio líquido',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta:
            'En relación a su liquidez disponible, más allá del monto que pretende invertir, ¿durante cuánto tiempo estaría en condiciones de asumir sus gastos totales, en caso de una eventual emergencia o imprevisto?',
        respuestas: [
            {
                respuesta: 'A. Menos de 3 meses',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre 3 y 6 meses',
                puntaje: 3,
            },
            {
                respuesta: 'C. Más de 6 meses ',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: 'De sus ingresos mensuales, ¿qué porcentaje aproximado destina a gastos fijos?',
        respuestas: [
            {
                respuesta: 'A. Más del 60%',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre un 30% y el 60%',
                puntaje: 3,
            },
            {
                respuesta: 'C. Menos del 30%',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Qué porcentaje de sus activos totales representan sus obligaciones financieras?',
        respuestas: [
            {
                respuesta: 'A. Más del 60%',
                puntaje: 1,
            },
            {
                respuesta: 'B. Entre un 30% y el 60%',
                puntaje: 3,
            },
            {
                respuesta: 'C. Menos del 30%',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: 'El objetivo de su inversión es:',
        respuestas: [
            {
                respuesta:
                    'A. Preservar el capital invertido. No quiero arriesgar mi inversión inicial. No me siento cómodo con fluctuaciones de valor en el corto plazo.',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Hacer crecer el capital invertido, ojalá con retornos estables. Estoy dispuesto a aceptar pequeñas fluctuaciones de valor en el mediano plazo, incluyendo posibles pérdidas menores en mi inversión inicial.',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Generar los mayores rendimientos posibles con el capital invertido, sin importar los riesgos. Para ello estoy dispuesto a aceptar fluctuaciones negativas de valor, incluyendo la posible pérdida de mi inversión inicial.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: 'En relación a sus decisiones de inversión, Usted:',
        respuestas: [
            {
                respuesta:
                    'A. No está dispuesto a asumir riesgos, estando consciente que el retorno será mucho menor. Vendería inmediatamente, si su inversión empieza a perder valor.',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Puede tolerar algo de riesgo, sabiendo que el retorno esperado será menor. Consultaría a un experto, si su inversión comienza a experimentar pérdidas.',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Busca asumir mayores riesgos, para obtener mejores retornos. Asumiría las pérdidas en el corto plazo y esperaría obtener ganancias en el largo plazo, si sus inversiones empezaran a perder valor.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Cuál es su nivel de conocimientos en materia de productos financieros y de inversión?',
        respuestas: [
            {
                respuesta: 'A. No tengo conocimientos en esta materia.',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Conozco algunos productos, pero no tengo mayor conocimiento respecto a sus características particulares y operatoria.',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Poseo amplios conocimientos de los mercados, y conozco bien el funcionamiento y riesgos de los instrumentos que se ofrecen o transan en ellos.',
                puntaje: 5,
            },
        ],
    },
    {
        pregunta: '¿Qué frase describe mejor su experiencia en inversiones?',
        respuestas: [
            {
                respuesta: 'A. Nunca he participado en el mercado de valores.',
                puntaje: 1,
            },
            {
                respuesta:
                    'B. Esporádicamente participo en el mercado de valores, pero para ello necesito asesoría especializada.',
                puntaje: 3,
            },
            {
                respuesta:
                    'C. Participo activamente en el mercado de valores y me siento cómodo tomando mis propias decisiones de inversión, sin que para ello requiera ser asesorado.',
                puntaje: 5,
            },
        ],
    },
];

const Cuestionario = () => {
    const [preguntas, setPreguntas] = useState([
        {
            pregunta: '',
            respuestas: [
                {
                    respuesta: '',
                    puntaje: 0,
                },
            ],
        },
    ]);
    const [preguntaActual, setPreguntaActual] = useState(0);
    const [puntaje, setPuntaje] = useState(0);
    const [step, setStep] = useState(1);
    // perfil = 0 = conservador ; = 1 = moderado ; =2 = agresivo
    const [perfil, setPerfil] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    let { type }: any = useParams();

    useEffect(() => {
        if (type === 'natural') {
            setPreguntas(preguntasNatural);
        } else {
            setPreguntas(preguntasJuridica);
        }
    }, [type]);

    useEffect(() => {
        dispatch(setPerfilInversionistaJuridica({ perfil: 'conservador' }));
        dispatch(setPerfilInversionistaNatural({ perfil: 'conservador' }));
        if (preguntaActual === preguntas.length) {
            if (18 < puntaje && puntaje <= 29) {
                setPerfil(1);
                dispatch(setPerfilInversionistaNatural({ perfil: 'moderado' }));
                dispatch(setPerfilInversionistaJuridica({ perfil: 'moderado' }));
            } else if (30 < puntaje && puntaje <= 45) {
                setPerfil(2);
                dispatch(setPerfilInversionistaNatural({ perfil: 'agresivo' }));
                dispatch(setPerfilInversionistaJuridica({ perfil: 'agresivo' }));
            }
            setShowResult(true);
        }
    }, [preguntaActual, preguntas.length, puntaje, dispatch]);

    useEffect(() => {
        if (showResult) {
            if (type === 'natural') dispatch(addPuntajeNatural({ puntaje: puntaje }));
        } else {
            dispatch(addPuntajeJuridica({ puntaje: puntaje }));
        }
    }, [showResult, dispatch, puntaje, type]);

    return (
        <div>
            <Layout>
                <div className="container page-container text-center">
                    <div className="text-center">
                        <span className="cuestionario-title">Definiendo tu perfil de inversionista</span>
                    </div>
                </div>
                <Stepper step={step} />
                <div className="container page-container text-center">
                    {showResult ? (
                        <ResultadoCuestionario type={type} perfiles={perfiles[perfil]} setStep={setStep} />
                    ) : preguntaActual !== preguntas.length ? (
                        <Pregunta
                            pregunta={preguntas[preguntaActual]}
                            setPuntaje={setPuntaje}
                            setPreguntaActual={setPreguntaActual}
                        ></Pregunta>
                    ) : (
                        ''
                    )}
                    {!showResult ? (
                        <div className="row prev-button-container">
                            <div className="col-lg-12 p-0 d-flex justify-content-start ">
                                <Boton
                                    text={'Anterior'}
                                    onClickFunc={() => {
                                        if (preguntaActual === 0) {
                                            history.push('/');
                                        } else {
                                            setPreguntaActual(preguntaActual - 1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </Layout>
        </div>
    );
};

export default Cuestionario;
