import React from 'react'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Layout: React.FC = (props) => {
  return (
    <>
      <Header />
        {props.children}
      <Footer />
    </>
  )
}

export default Layout