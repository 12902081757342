import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { ModalAnexoN1 } from '../../ModalAnexoN1/ModalAnexoN1';
import { ModalAnexoN2 } from '../../ModalAnexoN2/ModalAnexoN2';
import { useDispatch, useSelector } from 'react-redux';
import { FormularioAgenteJ } from '../../FormularioAgenteJ/FormularioAgenteJ';
import {
    // changeAgenteError,
    changeDatosFinalesField,
    changeErrDatosFinales,
} from '../../../redux/reducers/juridicaForm';

const popoverInversionista =
    '“Organizaciones que operan grandes volúmenes de activos: bancos, sociedades financieras, compañías de seguro, AFP, entidades nacionales de reaseguro y administradoras de fondos autorizados por ley.”';

const popoverCalificado =
    '“La normativa considera que se puede clasificar en dicha categoría a todos los inversionistas, los inversionistas institucionales, a los intermediarios de valores y a las personas naturales o jurídicas que declaren y acrediten contar con inversiones financieras no inferiores a 2.000 UF, y que adicionalmente cumplan con alguno de los siguientes requisitos: Contar con activos iguales o superiores a 10.000 UF. Haber realizado transacciones en el Mercado de Valores por un monto igual o superior a 1.000 UF, con una frecuencia mínima de 20 operaciones trimestrales, durante los últimos 4 años, o Contar con el conocimiento necesario para entender los riesgos que conlleva invertir en el mercado de valores.”';

export const DatosFinalesJuridicaForm = ({ setFormStep }: any) => {
    const [showPopoverInversionista, setShowPopoverInversionista] = useState(false);
    const [showPopoverCalificado, setShowPopoverCalificado] = useState(false);
    const [showModalN1, setShowModalN1] = useState(true);
    const [showModalN2, setShowModalN2] = useState(false);
    // const agenteData = useSelector((state: any) => state.juridicaForm.agente);
    const datosFinalesJuridica = useSelector((state: any) => state.juridicaForm.datosFinales);
    const errDatosFinales = useSelector((state: any) => state.juridicaForm.errDatosFinales);
    const dispatch = useDispatch();
    const history = useHistory();

    // const validateDatosFinales = ()=>{
    //     let isValid = true;
    //     if(datosFinalesJuridica.agente){
    //     Object.entries(agenteData).map((item: any) => {
    //         dispatch(changeAgenteError({ field: item[0], content: item[1] === '' }));
    //         if (item[1] === '') isValid = false;
    //         return null
    //     });
    // }

    //     return isValid;
    // }
    // const validateAgenteData = () => {
    //     let isValid = true;
    //     if (datosFinalesJuridica.agente) {
    //         Object.entries(agenteData).map((item: any) => {
    //             // if (item[0] === 'rolUnicoTributario') {
    //             dispatch(changeAgenteError({ field: item[0], content: item[1] === '' }));
    //             if (item[1] === '') isValid = false;
    //             // }
    //             return null;
    //         });
    //     }
    //     return isValid;
    // };

    const validateDatosFinales = () => {
        let isValid = true;
        if (datosFinalesJuridica.inversionistaInstitucional) {
            dispatch(changeErrDatosFinales({ field: 'anexoN1', content: !datosFinalesJuridica.anexoN1 }));
            isValid = datosFinalesJuridica.anexoN1;
        }
        if (datosFinalesJuridica.inversionistaCalificado) {
            dispatch(changeErrDatosFinales({ field: 'anexoN2', content: !datosFinalesJuridica.anexoN2 }));
            isValid = datosFinalesJuridica.anexoN2;
        }
        return isValid;
    };

    return (
        <div>
            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-12 input-box">
                        <label className="mr-2" htmlFor="">
                            ¿La Persona / Estructura Jurídica es inversionista institucional?{' '}
                            <FontAwesomeIcon
                                style={{ color: '#233746' }}
                                icon={faQuestionCircle}
                                onMouseEnter={() => setShowPopoverInversionista(true)}
                                onMouseLeave={() => setShowPopoverInversionista(false)}
                            />
                        </label>
                        <div className={`popover-custom ${!showPopoverInversionista ? 'hide' : ''}`}>
                            {popoverInversionista}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                            <span className="input-radio-box mb-2">
                                <input
                                    checked={datosFinalesJuridica.inversionistaInstitucional}
                                    className="input-radio"
                                    type="radio"
                                    name="inversionistaInstitucional"
                                    value="Si"
                                    onChange={() => {
                                        dispatch(
                                            changeDatosFinalesField({
                                                field: 'inversionistaInstitucional',
                                                content: true,
                                            })
                                        );
                                        setShowModalN1(true);
                                    }}
                                />
                                Si
                            </span>
                            <span className="input-radio-box">
                                <input
                                    checked={!datosFinalesJuridica.inversionistaInstitucional}
                                    className="input-radio"
                                    type="radio"
                                    name="inversionistaInstitucional"
                                    value="No"
                                    onChange={() =>
                                        dispatch(
                                            changeDatosFinalesField({
                                                field: 'inversionistaInstitucional',
                                                content: false,
                                            })
                                        )
                                    }
                                />
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {datosFinalesJuridica.inversionistaInstitucional && (
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        {errDatosFinales.anexoN1 ? <span className="error-color">*</span> : ''}
                        <input
                            checked={datosFinalesJuridica.anexoN1}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="anexoN1"
                            value="Si"
                            // onChange={() =>
                            //     dispatch(
                            //         changeDatosFinalesField({
                            //             field: 'anexoN1',
                            //             content: !datosFinalesJuridica.anexoN1,
                            //         })
                            //     )
                            // }
                        />
                        Acepto las condiciones que se informan en el Anexo N°1
                        <span className="link-style" onClick={() => setShowModalN1(true)}>
                            Leer y completar Anexo N°1
                        </span>
                        {errDatosFinales.anexoN1 && <p className="error-text error-color">*Campo obligatorio</p>}
                        <ModalAnexoN1 show={showModalN1} setShow={setShowModalN1} />
                    </span>
                </div>
            )}

            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-12 input-box">
                        <label className="mr-2" htmlFor="">
                            ¿Es la Personas / Estructura Jurídica inversionista calificado?{' '}
                            <FontAwesomeIcon
                                style={{ color: '#233746' }}
                                icon={faQuestionCircle}
                                onMouseEnter={() => setShowPopoverCalificado(true)}
                                onMouseLeave={() => setShowPopoverCalificado(false)}
                            />
                        </label>
                        <div className={`popover-custom ${!showPopoverCalificado ? 'hide' : ''}`}>
                            {popoverCalificado}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                            <span className="input-radio-box mb-2">
                                <input
                                    checked={datosFinalesJuridica.inversionistaCalificado}
                                    className="input-radio"
                                    type="radio"
                                    name="inversionistaCalificado"
                                    value="Si"
                                    onChange={() => {
                                        dispatch(
                                            changeDatosFinalesField({
                                                field: 'inversionistaCalificado',
                                                content: true,
                                            })
                                        );
                                        setShowModalN2(true);
                                    }}
                                />
                                Si
                            </span>
                            <span className="input-radio-box">
                                <input
                                    checked={!datosFinalesJuridica.inversionistaCalificado}
                                    className="input-radio"
                                    type="radio"
                                    name="inversionistaCalificado"
                                    value="No"
                                    onChange={() => {
                                        dispatch(
                                            changeDatosFinalesField({
                                                field: 'inversionistaCalificado',
                                                content: false,
                                            })
                                        );
                                    }}
                                />
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {datosFinalesJuridica.inversionistaCalificado && (
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        {errDatosFinales.anexoN2 ? <span className="error-color">*</span> : ''}
                        <input
                            checked={datosFinalesJuridica.anexoN2}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="anexoN2"
                            value="Si"
                            // onChange={() =>
                            //     dispatch(
                            //         changeDatosFinalesField({
                            //             field: 'anexoN2',
                            //             content: !datosFinalesJuridica.anexoN2,
                            //         })
                            //     )
                            // }
                        />
                        Acepto las condiciones que se informan en el Anexo N°2{' '}
                        <span className="link-style" onClick={() => setShowModalN2(true)}>
                            Leer y completar Anexo N°2{' '}
                        </span>
                        {errDatosFinales.anexoN2 && <p className="error-text error-color">*Campo obligatorio</p>}
                        <ModalAnexoN2 show={showModalN2} setShow={setShowModalN2} />
                    </span>
                </div>
            )}

            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-12 input-box" style={{ color: '#233746' }}>
                        <label className="mr-2" htmlFor="">
                            ¿Actua a través de un agente?{' '}
                        </label>
                    </div>
                    <div className="col-6">
                        <div className="col-12 d-flex flex-column justify-content-around align-items-left mt-2 ">
                            <span className="input-radio-box mb-2">
                                <input
                                    checked={datosFinalesJuridica.agente}
                                    className="input-radio"
                                    type="radio"
                                    name="agente"
                                    onChange={() => {
                                        dispatch(changeDatosFinalesField({ field: 'agente', content: true }));
                                    }}
                                />
                                Si
                            </span>
                            <span className="input-radio-box">
                                <input
                                    checked={!datosFinalesJuridica.agente}
                                    className="input-radio"
                                    type="radio"
                                    name="agente"
                                    onChange={() =>
                                        dispatch(
                                            changeDatosFinalesField({
                                                field: 'agente',
                                                content: false,
                                            })
                                        )
                                    }
                                />
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {datosFinalesJuridica.agente && <FormularioAgenteJ></FormularioAgenteJ>}

            <div className="col-12 mt-5 mb-3">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                        <div
                            className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                            onClick={() => {
                                setFormStep(2);
                            }}
                        >
                            Anterior
                        </div>
                    </div>
                    <div className="col"></div>
                    <div className="col-lg-3  col-sm-6 mt-1">
                        <div
                            className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                            onClick={() => {
                                if (
                                    // validateAgenteData() &&
                                    validateDatosFinales()
                                ) {
                                    history.push('/contrato/juridicaForm');
                                }
                            }}
                        >
                            Acepto las condiciones
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
