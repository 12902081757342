import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addRemoveOrigenPatrimonio,
    changePatrimonioError,
    changePatrimonioField,
} from '../../../redux/reducers/juridicaForm';
import { Input } from '../../Input/Input';

export const PatrimonioJuridicaForm = ({ setFormStep }: any) => {
    const dispatch = useDispatch();
    const errPatrimonio = useSelector((state: any) => state.juridicaForm.errPatrimonioJuridica);
    const patrimonioJuridica = useSelector((state: any) => state.juridicaForm.patrimonioJuridica);
    const validatePatrimonio = () => {
        dispatch(changePatrimonioError({ field: 'origen', content: patrimonioJuridica.origen.length === 0 }));
        Object.entries(patrimonioJuridica).map((item: any) => {
            if (item[0] === 'otroOrigen' && patrimonioJuridica.origen.includes('Otros')) {
                dispatch(changePatrimonioError({ field: 'otroOrigen', content: item[1] === '' }));
            }
            return null;
        });

        return returnValidation();
    };
    const returnValidation = () => {
        let isValid = true;
        isValid = patrimonioJuridica.origen.length !== 0;
        Object.entries(patrimonioJuridica).map((item: any) => {
            if (item[0] === 'otroOrigen' && patrimonioJuridica.origen.includes('Otros')) {
                isValid = item[1] !== '';
            }
            return null;
        });
        return isValid;
    };

    return (
        <div>
            <div className="row">
                <div className="col-6 input-box">
                    <label>
                        {errPatrimonio.patrimonio ? <span className="error-color">*</span> : ''}
                        El Patrimonio de la Persona Jurídica es:
                    </label>
                </div>
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.patrimonio === 'Menor de $100 millones'}
                            className="input-radio mr-3"
                            type="radio"
                            name="USPerson"
                            value="Si"
                            onChange={() =>
                                dispatch(
                                    changePatrimonioField({ field: 'patrimonio', content: 'Menor de $100 millones' })
                                )
                            }
                        />
                        Menor de $100 millones
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.patrimonio === 'Entre $ 100 millones y $ 500 millones'}
                            className="input-radio mr-3"
                            type="radio"
                            name="USPerson"
                            value="No"
                            onChange={() =>
                                dispatch(
                                    changePatrimonioField({
                                        field: 'patrimonio',
                                        content: 'Entre $ 100 millones y $ 500 millones',
                                    })
                                )
                            }
                        />
                        Entre $100 millones y $500 millones
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.patrimonio === 'Mas de $ 500 millones'}
                            className="input-radio mr-3"
                            type="radio"
                            name="USPerson"
                            value="No"
                            onChange={() =>
                                dispatch(
                                    changePatrimonioField({
                                        field: 'patrimonio',
                                        content: 'Mas de $ 500 millones',
                                    })
                                )
                            }
                        />
                        Más de $500 millones
                    </span>
                </div>
                <div className="col-12 input-box">
                    <label>
                        {errPatrimonio.origen ? <span className="error-color">*</span> : ''}
                        Indique el origen de su patrimonio
                    </label>
                    {errPatrimonio.origen && <span className="error-text error-color">*Campo obligatorio</span>}
                </div>
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.origen.includes('Ingresos anuales del giro de la empresa')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Si"
                            onChange={() =>
                                dispatch(
                                    addRemoveOrigenPatrimonio({ content: 'Ingresos anuales del giro de la empresa' })
                                )
                            }
                        />
                        Ingresos anuales del giro de la empresa
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.origen.includes(
                                'Vencimiento o rescate de otras Inversiones Financieras'
                            )}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="No"
                            onChange={() =>
                                dispatch(
                                    addRemoveOrigenPatrimonio({
                                        content: 'Vencimiento o rescate de otras Inversiones Financieras',
                                    })
                                )
                            }
                        />
                        Vencimiento o rescate de otras Inversiones Financieras
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.origen.includes(
                                'Ventas de Activos (propiedades, empresas, etc)'
                            )}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="No"
                            onChange={() =>
                                dispatch(
                                    addRemoveOrigenPatrimonio({
                                        content: 'Ventas de Activos (propiedades, empresas, etc)',
                                    })
                                )
                            }
                        />
                        Ventas de Activos (propiedades, empresas, etc)
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.origen.includes('Legado/Donación')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="No"
                            onChange={() => dispatch(addRemoveOrigenPatrimonio({ content: 'Legado/Donación' }))}
                        />
                        Legado / Donación
                    </span>

                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={patrimonioJuridica.origen.includes('Otros')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Otros"
                            onChange={(e) => dispatch(addRemoveOrigenPatrimonio({ content: e.target.value }))}
                        />
                        Otros
                    </span>
                </div>
                {patrimonioJuridica.origen.includes('Otros') && (
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                        placeholder={'Otro'}
                        state={patrimonioJuridica.otroOrigen}
                        field={'otroOrigen'}
                        label={'Especificar'}
                        error={errPatrimonio.otroOrigen}
                        reducer={changePatrimonioField}
                    />
                )}
                <div className="col-12 mt-5 mb-3">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                            <div
                                className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                onClick={() => {
                                    setFormStep(1);
                                }}
                            >
                                Anterior
                            </div>
                        </div>
                        <div className="col"></div>
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                            <div
                                className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                onClick={() => {
                                    if (validatePatrimonio()) {
                                        setFormStep(3);
                                    }
                                }}
                            >
                                Continuar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
