import React, { useEffect, useState } from 'react';
import { AClienteForm } from './AClienteForm/AClienteForm';
import { ARepresentanteLegalList } from './ARepresentanteLegal/ARepresentanteLegalList';
import { PatrimonioJuridicaForm } from './PatrimonioJuridicaForm/PatrimonioJuridicaForm';
import './JuridicaForm.scss';
import { DatosFinalesJuridicaForm } from './DatosFinalesJuridicaForm/DatosFinalesJuridicaForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeJuridicaError,
    changeNacionalidadDireccionError,
    changeNacionalidadesError,
    changePatrimonioError,
    changeRepresentanteLegalError,
} from '../../redux/reducers/juridicaForm';
export const JuridicaForm = () => {
    const dispatch = useDispatch();
    const [formStep, setFormStep] = useState(0);

    const aClienteState = useSelector((state: any) => state.juridicaForm.aCliente);
    const masNacionalidades = useSelector((state: any) => state.juridicaForm.masNacionalidades);
    const representantesLegales = useSelector((state: any) => state.juridicaForm.representantesLegales);
    const patrimonioJuridica = useSelector((state: any) => state.juridicaForm.patrimonioJuridica);

    const validateAClienteData = () => {
        let isValid = true;
        Object.entries(aClienteState).map((item: any) => {
            dispatch(changeJuridicaError({ field: item[0], content: item[1] === '' }));
            if (item[1] === '') isValid = false;
            return null;
        });
        if (aClienteState.domicilioFiscal) {
            Object.entries(masNacionalidades).map((item: any, i: any) => {
                dispatch(changeNacionalidadesError({ index: i, content: item[1].nacionalidad === '' }));
                dispatch(changeNacionalidadDireccionError({ index: i, content: item[1].direccion === '' }));
                if (item[1].nacionalidad === '' || item[1].direccion === '') isValid = false;
                return null;
            });
        }

        return isValid;
    };
    const validateRepresentantesLegales = () => {
        let isValid = true;
        representantesLegales.map((item: any, i: any) => {
            Object.entries(item).map((key: any, keyI: any) => {
                dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: key[1] === '' }));
                if (key[1] === '') isValid = false;
                return null;
            });
            return null;
        });
        return isValid;
    };
    const validatePatrimonio = () => {
        let isValid = true;
        if (patrimonioJuridica.origen.length === 0) {
            isValid = false;
            dispatch(changePatrimonioError({ field: 'origen', content: patrimonioJuridica.origen.length === 0 }));
        } else {
            dispatch(changePatrimonioError({ field: 'origen', content: patrimonioJuridica.origen.length === 0 }));
        }
        return isValid;
    };
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [formStep]);
    return (
        <div className="juridica-form-page">
            <div className="row juridica-form-box d-flex justify-content-center">
                <div className="col-xl-12">
                    <div className="row d-flex justify-content-center">
                        <div className="col-xl-8">
                            <div className="row form-stepper">
                                <div
                                    className={`d-flex justify-content-center form-stepper-item ${
                                        formStep === 0 ? 'form-stepper-item-active' : ''
                                    }`}
                                    onClick={() => {
                                        setFormStep(0);
                                    }}
                                >
                                    Antecedentes del cliente
                                </div>
                                <div className="form-stepper-item">{'|'}</div>
                                <div
                                    className={`form-stepper-item ${formStep === 1 ? 'form-stepper-item-active' : ''} `}
                                    onClick={() => {
                                        if (validateAClienteData()) {
                                            setFormStep(1);
                                        }
                                    }}
                                >
                                    Antecedentes representante legal
                                </div>
                                <div className="form-stepper-item">{'|'}</div>
                                <div
                                    className={`form-stepper-item ${formStep === 2 ? 'form-stepper-item-active' : ''} `}
                                    onClick={() => {
                                        if (validateAClienteData() && validateRepresentantesLegales()) {
                                            setFormStep(2);
                                        }
                                    }}
                                >
                                    Patrimonio persona jurídica / estructura jurídica
                                </div>
                                <div className="form-stepper-item">{'|'}</div>
                                <div
                                    className={`form-stepper-item ${formStep === 3 ? 'form-stepper-item-active' : ''} `}
                                    onClick={() => {
                                        if (
                                            validateAClienteData() &&
                                            validateRepresentantesLegales() &&
                                            validatePatrimonio()
                                        ) {
                                            setFormStep(3);
                                        }
                                    }}
                                >
                                    Datos finales
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                    <div className="row d-flex justify-content-center">
                        <div className="col-11">
                            {formStep === 0 ? (
                                <AClienteForm setFormStep={setFormStep} />
                            ) : formStep === 1 ? (
                                <ARepresentanteLegalList setFormStep={setFormStep} />
                            ) : formStep === 2 ? (
                                <PatrimonioJuridicaForm setFormStep={setFormStep} />
                            ) : (
                                <DatosFinalesJuridicaForm setFormStep={setFormStep} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
