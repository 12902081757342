import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLaboralField, changeOcupacion, changeOrigenMonetario } from '../../../redux/reducers/naturalForm';
import { Input } from '../../Input/Input';
import './ALaboralesNaturalForm.scss';
export const ALaboralesNaturalForm = ({ setFormStep, validateALaborales }: any) => {
    const aLaborales = useSelector((state: any) => state.naturalForm.aLaborales);
    const errALaborales = useSelector((state: any) => state.naturalForm.errALaborales);
    const ocupacion = useSelector((state: any) => state.naturalForm.ocupacion);
    const origenMonetario = useSelector((state: any) => state.naturalForm.origenMonetario);

    const dispatch = useDispatch();

    return (
        <>
            <div className="row alaborales-page">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 input-box">
                    <label htmlFor="">
                        {errALaborales.ocupacion ? <span className="error-color">*</span> : ''}Seleccione la opción
                        correspondiente
                    </label>
                    {errALaborales.ocupacion && <span className="error-text error-color">*Campo obligatorio</span>}
                </div>

                <div className="col-12">
                    <label className="input-checkbox mr-4 mt-3">
                        <input
                            checked={ocupacion.includes('Empleado/a')}
                            type="checkbox"
                            id="cbox1"
                            value="Empleado/a"
                            onChange={(e) => dispatch(changeOcupacion({ item: e.target.value }))}
                        />
                        Empleado/a
                    </label>
                    <label className="input-checkbox mr-4">
                        <input
                            checked={ocupacion.includes('Empresario/a')}
                            type="checkbox"
                            id="cbox1"
                            value="Empresario/a"
                            onChange={(e) => dispatch(changeOcupacion({ item: e.target.value }))}
                        />
                        Empresario/a
                    </label>
                    <label className="input-checkbox mr-4">
                        <input
                            checked={ocupacion.includes('Independiente')}
                            type="checkbox"
                            id="cbox1"
                            value="Independiente"
                            onChange={(e) => dispatch(changeOcupacion({ item: e.target.value }))}
                        />
                        Independiente
                    </label>
                    <label className="input-checkbox mr-4">
                        <input
                            checked={ocupacion.includes('Rentista')}
                            type="checkbox"
                            id="cbox1"
                            value="Rentista"
                            onChange={(e) => dispatch(changeOcupacion({ item: e.target.value }))}
                        />
                        Rentista
                    </label>
                    <label className="input-checkbox mr-4">
                        <input
                            checked={ocupacion.includes('Jubilado')}
                            type="checkbox"
                            id="cbox1"
                            value="Jubilado"
                            onChange={(e) => dispatch(changeOcupacion({ item: e.target.value }))}
                        />
                        Jubilado
                    </label>
                    <label className="input-checkbox mr-4">
                        <input
                            checked={ocupacion.includes('Otro')}
                            type="checkbox"
                            id="cbox1"
                            value="Otro"
                            onChange={(e) => dispatch(changeOcupacion({ item: e.target.value }))}
                        />
                        Otro
                    </label>
                    {ocupacion.includes('Otro') && (
                        <Input
                            labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                            inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                            label={'Especificar'}
                            placeholder={'Otro'}
                            state={aLaborales.otraOcupacion}
                            field={'otraOcupacion'}
                            error={errALaborales.otraOcupacion}
                            reducer={changeLaboralField}
                        />
                    )}
                </div>
                <Input
                    labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                    inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                    label={'Profesión'}
                    placeholder={'Tu profesión'}
                    state={aLaborales.profesion}
                    field={'profesion'}
                    error={errALaborales.profesion}
                    reducer={changeLaboralField}
                />
                <Input
                    labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                    inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                    label={'Nombre de la empresa'}
                    placeholder={'Empresa'}
                    state={aLaborales.nombreEmpresa}
                    field={'nombreEmpresa'}
                    error={errALaborales.nombreEmpresa}
                    reducer={changeLaboralField}
                />

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 input-box ">
                    <label>
                        {errALaborales.ingresoMensual ? <span className="error-color">*</span> : ''}Ingreso promedio
                        mensual
                    </label>
                    {errALaborales.ingresoMensual && <span className="error-text error-color">*Campo obligatorio</span>}
                </div>
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={aLaborales.ingresoMensual === 'Menos de $1.000.000'}
                            className="input-radio mr-3"
                            type="radio"
                            name="USPerson"
                            value="Menos de $1.000.000"
                            onChange={(e) =>
                                dispatch(changeLaboralField({ field: 'ingresoMensual', content: e.target.value }))
                            }
                        />
                        Menos de $1.000.000
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={aLaborales.ingresoMensual === '$1.000.000 - $3.000.000'}
                            className="input-radio mr-3"
                            type="radio"
                            name="USPerson"
                            value="$1.000.000 - $3.000.000"
                            onChange={(e) =>
                                dispatch(changeLaboralField({ field: 'ingresoMensual', content: e.target.value }))
                            }
                        />
                        $1.000.000 - $3.000.000
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={aLaborales.ingresoMensual === '$3.000.000 ó más.'}
                            className="input-radio mr-3"
                            type="radio"
                            name="USPerson"
                            value="$3.000.000 ó más."
                            onChange={(e) =>
                                dispatch(changeLaboralField({ field: 'ingresoMensual', content: e.target.value }))
                            }
                        />
                        $3.000.000 ó más.
                    </span>
                </div>
                <div className="col-12 input-box">
                    <label>
                        {errALaborales.origenMonetario ? <span className="error-color">*</span> : ''}¿De dónde proviene
                        la mayor parte del dinero que quieres invertir?
                    </label>
                    {errALaborales.origenMonetario && (
                        <span className="error-text error-color">*Campo obligatorio</span>
                    )}
                </div>
                <div className="col-12 d-flex flex-column justify-content-around align-items-left">
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2 mt-3">
                        <input
                            checked={origenMonetario.includes('Honorarios/Sueldos')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Honorarios/Sueldos"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Honorarios/Sueldos
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={origenMonetario.includes('Inversiones')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Inversiones"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Inversiones
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={origenMonetario.includes('Actividad productiva')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Actividad productiva"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Actividad productiva
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={origenMonetario.includes('Propiedades')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Propiedades"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Propiedades
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={origenMonetario.includes('Herencia')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Herencia"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Herencia
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={origenMonetario.includes('Ahorros')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Ahorros"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Ahorros
                    </span>
                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                        <input
                            checked={origenMonetario.includes('Otros')}
                            className="input-radio mr-3"
                            type="checkbox"
                            name="USPerson"
                            value="Otros"
                            onChange={(e) => dispatch(changeOrigenMonetario({ item: e.target.value }))}
                        />
                        Otros
                    </span>
                </div>
                {origenMonetario.includes('Otros') && (
                    <Input
                        labelClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box'}
                        inputClass={'col-lg-3 col-md-3 col-sm-3 col-xs-5 input-box '}
                        placeholder={'Otro'}
                        state={aLaborales.otroOrigen}
                        field={'otroOrigen'}
                        label={'Especificar'}
                        error={errALaborales.otroOrigen}
                        reducer={changeLaboralField}
                    />
                )}

                <div className="col-12 mt-5 mb-3">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                            <div
                                className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                onClick={() => {
                                    setFormStep(0);
                                }}
                            >
                                Anterior
                            </div>
                        </div>
                        <div className="col"></div>
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                            <div
                                className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                onClick={() => {
                                    if (validateALaborales()) {
                                        setFormStep(2);
                                    }
                                }}
                            >
                                Continuar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
