export const patrimonioJuridica = {
    patrimonio: 'Menor de $100 millones',
    origen: [],
    otroOrigen:''
};

export const errPatrimonioJuridica = {
    patrimonio: false,
    origen: false,
    otroOrigen:false
};
