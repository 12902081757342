export const aPersonales = {
    rutPasaporte: '',
    nombres: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    paisNacimiento: 'Chile',
    sexo: 'Masculino',
    estadoCivil: 'Soltero',
    nacionalidadPrincipal: 'Chilena',
    usPerson: false,
    otraNacionalidad: false,
    domicilioFiscal: false,
    telefono: '',
    email: '',
    direccion: '',
    numero: '',
    dpto: '',
    ciudad: '',
    comuna: '',
    representanteLegalRadio: false,
    personaPolitica: false,
    cedulaFrente: [],
    cedulaDorso: [],
};
export const direccionDomicilioFiscal = '';
export const errDireccionDomicilioFiscal = false;

export const errAPersonales = {
    rutPasaporte: false,
    nombres: false,
    apellidoPaterno: false,
    apellidoMaterno: false,
    fechaNacimiento: false,
    paisNacimiento: false,
    sexo: false,
    estadoCivil: false,
    nacionalidadPrincipal: false,
    usPerson: false,
    domicilioFiscal: false,
    telefono: false,
    email: false,
    direccion: false,
    numero: false,
    ciudad: false,
    comuna: false,
    cedulaFrente: false,
    cedulaDorso: false,
};

export const masNacionalidades = [{ nacionalidad: 'Chilena', direccion: '' }];

export const errMasNacionalidades = [{ nacionalidad: false, direccion: false }];

export const representantesLegales = [
    {
        rutPasaporte: '',
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        relacion: 'Mandatario',
        otro: '',
        telefono: '',
        email: '',
        documentos: [],
        // rutRepresentanteLegal: '',
    },
];

export const representanteLegal = {
    rutPasaporte: '',
    nombres: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    relacion: 'Mandatario',
    telefono: '',
    email: '',
    otro: '',
    documentos: [],
    // rutRepresentanteLegal: '',
};

export const errRepresentanteLegal = {
    rutPasaporte: false,
    nombres: false,
    apellidoPaterno: false,
    apellidoMaterno: false,
    Relacion: false,
    telefono: false,
    email: false,
    documentos: false,
    // rutRepresentanteLegal: false,
};

export const errRepresentantesLegales = [
    {
        rutPasaporte: false,
        nombres: false,
        apellidoPaterno: false,
        apellidoMaterno: false,
        Relacion: false,
        telefono: false,
        email: false,
        documentos: false,
        // rutRepresentanteLegal: false,
    },
];
