import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { addCedulaFrente, removeCedulaFrente } from '../../redux/reducers/naturalForm';
import iconUpload from '../../assets/svg/upload-icon.svg';
import iconClose from '../../assets/svg/icon-close.svg';

export const FrenteDropzone = () => {
    const [cedulaFrente, setCedulaFrente] = useState([]);
    const dispatch = useDispatch();
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: 'image/jpeg,image/png,.pdf',
        maxFiles: 1,
        maxSize: 4000000,
        onDrop: (acceptedFiles: any) => {
            setCedulaFrente(acceptedFiles);
        },
    });
    const files = useSelector((state: any) => state.naturalForm.aPersonales.cedulaFrente);
    const fileRejectionItems = fileRejections.map(({ file, errors }, i: any) => {
        return (
            <li key={file.name}>
                {file.name} - {file.size} bytes
                <ul>
                    {errors.map((e) => {
                        if (e.message === 'File is larger than 4000000 bytes') {
                            e.message = 'El archivo no debe ser superior a 4MB';
                        }
                        if (e.message === 'File type must be image/jpeg,image/png,.pdf') {
                            e.message = 'El tipo de archivo debe ser .jpeg, .png o .pdf';
                        }
                        if (e.message === 'Too many files') {
                            e.message = 'Debe ingresar un unico archivo';
                        }

                        return <li key={e.code}>{e.message}</li>;
                    })}
                </ul>
            </li>
        );
    });
    useEffect(() => {
        if (cedulaFrente.length !== 0) dispatch(addCedulaFrente({ file: cedulaFrente[0] }));
    }, [cedulaFrente, dispatch]);

    return (
        <div className="row">
            <div className="col-12 p-0 mb-2 ">
                <span className="modal-title">Cédula de Identidad - Frente</span>
            </div>
            <div {...getRootProps()} className="col-12 d-flex justify-content-center align-center upload-file-box">
                <input {...getInputProps()} />
                <div className="row d-flex justify-content-center align-center">
                    <div className="col-5 d-flex justify-content-center align-center mb-3">
                        <img src={iconUpload} alt="upload-icon" />
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center mb-4">
                                <span>Arrasta tus documentos aquí.</span>
                            </div>
                            <div className="col-12 text-center">(Formato JPG, PNG ó PDF con un máximo de 9MB)</div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            {files.map((el: any, i: number) => {
                return (
                    <div key={i} className="d-flex justify-content-around align-items-center mr-4">
                        <span className="mr-3">{el.path} </span>
                        <img
                            style={{ width: '16px', cursor: 'pointer' }}
                            src={iconClose}
                            alt=""
                            onClick={() => dispatch(removeCedulaFrente({ index: i }))}
                        />
                    </div>
                );
            })}
            {fileRejectionItems}
        </div>
    );
};
