const nacionalidad = [
    { name: 'Chileno' },
    { name: 'Afegao' },
    { name: 'Americano' },
    { name: 'Andorrano' },
    { name: 'Angolano' },
    { name: 'Antiguano' },
    { name: 'Agelino' },
    { name: 'Argentino' },
    { name: 'Armenio' },
    { name: 'Árabe' },
    { name: 'Australiano' },
    { name: 'Austríaco' },
    { name: 'Azeri' },
    { name: 'Bahamense' },
    { name: 'Bangladês' },
    { name: 'Barbadiano' },
    { name: 'Baremita' },
    { name: 'Bielorrusso' },
    { name: 'Belga' },
    { name: 'Belizenho' },
    { name: 'Beninense' },
    { name: 'Boliviano' },
    { name: 'Bósnio' },
    { name: 'Bechuano' },
    { name: 'Brasileiro' },
    { name: 'Britânico' },
    { name: 'Bruneano' },
    { name: 'Búlgaro' },
    { name: 'Burquinense' },
    { name: 'Burundês' },
    { name: 'Butanense' },
    { name: 'Cabo-verdiano' },
    { name: 'Camaronense' },
    { name: 'Cambojano' },
    { name: 'Canadense' },
    { name: 'Centroafricano' },
    { name: 'Chadiano' },
    { name: 'Chinês' },
    { name: 'Chileno' },
    { name: 'Cookiano' },
    { name: 'Colombiano' },
    { name: 'Comoriano' },
    { name: 'costa-riquenho' },
    { name: 'Costa-marfinense' },
    { name: 'Croata' },
    { name: 'Cubano' },
    { name: 'Cipriota' },
    { name: 'Tcheco' },
    { name: 'Congolense' },
    { name: 'Dinamarquês' },
    { name: 'Djibutiense' },
    { name: 'Dominiquense' },
    { name: 'Dominicano' },
    { name: 'Timorense' },
    { name: 'Equatoriano' },
    { name: 'Egípcio' },
    { name: 'Salvadorenho' },
    { name: 'Inglês' },
    { name: 'Guinéu-equatoriano' },
    { name: 'Eritreu' },
    { name: 'Estoniano' },
    { name: 'Fijiano' },
    { name: 'Finlandês' },
    { name: 'Francês' },
    { name: 'Gabonense' },
    { name: 'Gambiano' },
    { name: 'Geórgico' },
    { name: 'Alemão' },
    { name: 'Granadino' },
    { name: 'Grego' },
    { name: 'Guatemalteco' },
    { name: 'Guineano' },
    { name: 'Guineense' },
    { name: 'Guianense' },
    { name: 'Haitiano' },
    { name: 'Holandês' },
    { name: 'Hondurenho' },
    { name: 'Húngaro' },
    { name: 'Islandês' },
    { name: 'Indiano' },
    { name: 'Indonésio' },
    { name: 'Iraniano' },
    { name: 'Irlandês' },
    { name: 'Israelita' },
    { name: 'Italiano' },
    { name: 'Jamaicano' },
    { name: 'Japonês' },
    { name: 'Jordao' },
    { name: 'Cazaque' },
    { name: 'Queniano' },
    { name: 'Quiribatiano' },
    { name: 'Quirguistanês' },
    { name: 'Kuwaitiano' },
    { name: 'Laosiano' },
    { name: 'Letoniano' },
    { name: 'Libanês' },
    { name: 'Lesotiano' },
    { name: 'Liberiano' },
    { name: 'Liechtensteinense' },
    { name: 'Lituano' },
    { name: 'Luxemburguês' },
    { name: 'Líbio' },
    { name: 'Macedônio' },
    { name: 'Madagascarense' },
    { name: 'Malaio' },
    { name: 'Malauiano' },
    { name: 'Maldivo' },
    { name: 'Maliano' },
    { name: 'Maltês' },
    { name: 'Mauriciano' },
    { name: 'Mauritano' },
    { name: 'Marshallino' },
    { name: 'Micronésio' },
    { name: 'Mexicano' },
    { name: 'Marroquino' },
    { name: 'Moldávio' },
    { name: 'Monegasco' },
    { name: 'Mongol' },
    { name: 'Montenegrino' },
    { name: 'Mocambicano' },
    { name: 'Birmanés' },
    { name: 'Namibiano' },
    { name: 'Nauruano' },
    { name: 'Nepalês' },
    { name: 'Neozelandês' },
    { name: 'Nicaraguense' },
    { name: 'Nigerino' },
    { name: 'Nigeriano' },
    { name: 'Niuano' },
    { name: 'Norte-coreano' },
    { name: 'Norueguês' },
    { name: 'Omanense' },
    { name: 'Palestino' },
    { name: 'Paquistanês' },
    { name: 'Palauense' },
    { name: 'Panamenho' },
    { name: 'Papuásio' },
    { name: 'Paraguaio' },
    { name: 'Peruano' },
    { name: 'Pilipino' },
    { name: 'Polonês' },
    { name: 'Português' },
    { name: 'Catarense' },
    { name: 'Romeno' },
    { name: 'Russoruandês' },
    { name: 'Samoano' },
    { name: 'Santa-lucense' },
    { name: 'São-cristovense' },
    { name: 'São-marinense' },
    { name: 'São-tomense' },
    { name: 'São-vicentino' },
    { name: 'Escocês' },
    { name: 'Senegalense' },
    { name: 'Servio' },
    { name: 'Seichelense' },
    { name: 'Serra-leonês' },
    { name: 'Singapurense' },
    { name: 'Eslovaco' },
    { name: 'Salomônico' },
    { name: 'Somali' },
    { name: 'Sul-africano' },
    { name: 'Coreano' },
    { name: 'Sul-sudanense' },
    { name: 'Espanhol' },
    { name: 'Srilankês' },
    { name: 'Sudanense' },
    { name: 'Surinamês' },
    { name: 'Suazi' },
    { name: 'Sueco' },
    { name: 'Suíço' },
    { name: 'Sírio' },
    { name: 'Tadiquistão' },
    { name: 'Tajique' },
    { name: 'Tanzaniano' },
    { name: 'Tailandês' },
    { name: 'Togolês' },
    { name: 'Tonganês' },
    { name: 'Trinitário' },
    { name: 'Tunisiano' },
    { name: 'Turcomeno' },
    { name: 'Turco' },
    { name: 'Tuvaluano' },
    { name: 'Ucraniano' },
    { name: 'Ugandês' },
    { name: 'Uruguaio' },
    { name: 'Uzbeque' },
    { name: 'Vanuatuano' },
    { name: 'Venezuelano' },
    { name: 'Vietnamita' },
    { name: 'Galês' },
    { name: 'Iemenita' },
    { name: 'Zambiano' },
    { name: 'Zimbabueano' },
];

export { nacionalidad };
