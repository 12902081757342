import React from 'react';
import './ModalCedula.scss';
import { Modal, ModalBody } from 'reactstrap';
import iconClose from '../../assets/svg/icon-close.svg';
import { DorsoDropzone } from './DorsoDropzone';
import { FrenteDropzone } from './FrenteDropzone';

export const ModalCedula = ({ show, setShow, index }: any) => {
    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} size="lg" centered>
            <ModalBody style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col-12 pru">
                        <img
                            src={iconClose}
                            onClick={() => setShow(!show)}
                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            alt="icon-close"
                        />
                    </div>
                </div>
                <div className="row d-flex align-center justify-content-center">
                    <div className="col-8">
                        <FrenteDropzone index={index} />
                        <DorsoDropzone index={index} />
                        <div className="row mt-4">
                            <div className="col-12 mt-4">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                        <div
                                            className="btn btn-primary btn-home btn-avante-primary d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                setShow(!show);
                                            }}
                                        >
                                            Continuar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
