import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeRepresentanteLegalField,
    removeCedulaDorsoRepresentanteLegal,
    removeCedulaFrenteRepresentanteLegal,
    removeRepresentanteLegal,
} from '../../../../redux/reducers/juridicaForm';
import iconClose from '../../../../assets/svg/icon-close.svg';
import { ModalCedula } from '../../../ModalCedulaARepLegal/ModalCedula';

export const ARepresentanteLegalForm = ({ i }: any) => {
    const dispatch = useDispatch();
    const [showModalCedula, setShowModalCedula] = useState(false);
    const representantesLegales = useSelector((state: any) => state.juridicaForm.representantesLegales);
    const errRepresentantesLegales = useSelector((state: any) => state.juridicaForm.errRepresentantesLegales);
    return (
        <form className="mt-2 mb-5" action="" key={i}>
            {i !== 0 && i === representantesLegales.length - 1 ? (
                <div className={`row`}>
                    <div className="col-12 pru  ">
                        <img
                            src={iconClose}
                            onClick={() => {
                                dispatch(removeRepresentanteLegal({ index: i }));
                            }}
                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            alt="icon-close"
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className="row representante-legal-title">
                <span>Representante Legal {i + 1}</span>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {' '}
                        {errRepresentantesLegales[i].rutPasaporte ? <span className="error-color">*</span> : ''}
                        Rut/Pasaporte
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].rutPasaporte ? 'input-error' : ''}`}
                        placeholder="Rut/Pasaporte"
                        type="text"
                        value={representantesLegales[i].rutPasaporte}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'rutPasaporte',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].rutPasaporte && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].nombres ? <span className="error-color">*</span> : ''}
                        Nombres
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].nombres ? 'input-error' : ''}`}
                        placeholder="Nombres"
                        type="text"
                        value={representantesLegales[i].nombres}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'nombres',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].nombres && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].apellidoPaterno ? <span className="error-color">*</span> : ''}
                        Apellido Paterno
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].apellidoPaterno ? 'input-error' : ''}`}
                        placeholder="Apellido"
                        type="text"
                        value={representantesLegales[i].apellidoPaterno}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'apellidoPaterno',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].apellidoPaterno && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].apellidoMaterno ? <span className="error-color">*</span> : ''}
                        Apellido Materno
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].apellidoMaterno ? 'input-error' : ''}`}
                        placeholder="Apellido"
                        type="text"
                        value={representantesLegales[i].apellidoMaterno}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'apellidoMaterno',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].apellidoMaterno && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].telefono ? <span className="error-color">*</span> : ''}
                        Teléfono
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].telefono ? 'input-error' : ''}`}
                        placeholder="+56 9 7485120-5"
                        type="text"
                        value={representantesLegales[i].telefono}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'telefono',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].telefono && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].email ? <span className="error-color">*</span> : ''}
                        Email
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].email ? 'input-error' : ''}`}
                        placeholder="Email"
                        type="text"
                        value={representantesLegales[i].email}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'email',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].email && <p className="error-text error-color">*Email inválido</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        Dirección
                        {errRepresentantesLegales[i].direccion ? <span className="error-color">*</span> : ''}
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errRepresentantesLegales[i].direccion ? 'input-error' : ''}`}
                        placeholder="Dirección"
                        type="text"
                        value={representantesLegales[i].direccion}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'direccion',
                                    content: e.target.value,
                                })
                            )
                        }
                    />
                    {errRepresentantesLegales[i].direccion && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6 input-box">
                            <label>
                                {errRepresentantesLegales[i].numero ? <span className="error-color">*</span> : ''}
                                Número
                            </label>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-3 col-xs-6 input-box">
                            <input
                                className={`input-activo ${errRepresentantesLegales[i].numero ? 'input-error' : ''}`}
                                placeholder="0000"
                                type="text"
                                value={representantesLegales[i].numero}
                                onChange={(e) =>
                                    dispatch(
                                        changeRepresentanteLegalField({
                                            index: i,
                                            field: 'numero',
                                            content: e.target.value,
                                        })
                                    )
                                }
                            />
                            {errRepresentantesLegales[i].numero && (
                                <p className="error-text error-color">*Campo obligatorio</p>
                            )}
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6 input-box">
                            <label>
                                {errRepresentantesLegales[i].dpto ? <span className="error-color">*</span> : ''}
                                Dpto
                            </label>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-3 col-xs-6 input-box">
                            <input
                                className={`input-activo ${errRepresentantesLegales[i].dpto ? 'input-error' : ''}`}
                                placeholder="3 A"
                                type="text"
                                value={representantesLegales[i].dpto}
                                onChange={(e) =>
                                    dispatch(
                                        changeRepresentanteLegalField({
                                            index: i,
                                            field: 'dpto',
                                            content: e.target.value,
                                        })
                                    )
                                }
                            />
                            {errRepresentantesLegales[i].dpto && (
                                <p className="error-text error-color">*Campo obligatorio</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].ciudad ? <span className="error-color">*</span> : ''}
                        Ciudad
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-select input-activo ${
                            errRepresentantesLegales[i].ciudad ? 'input-error' : ''
                        }`}
                        placeholder="Ciudad"
                        value={representantesLegales[i].ciudad}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'ciudad',
                                    content: e.target.value,
                                })
                            )
                        }
                    />

                    {errRepresentantesLegales[i].ciudad && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales[i].comuna ? <span className="error-color">*</span> : ''}
                        Comuna
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-select input-activo ${
                            errRepresentantesLegales[i].comuna ? 'input-error' : ''
                        }`}
                        placeholder="Comuna"
                        value={representantesLegales[i].comuna}
                        onChange={(e) =>
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: i,
                                    field: 'comuna',
                                    content: e.target.value,
                                })
                            )
                        }
                    />

                    {errRepresentantesLegales[i].comuna && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                            <label>
                                {errRepresentantesLegales[i].relacion ? <span className="error-color">*</span> : ''}
                                Relación con la sociedad
                            </label>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-6 input-box">
                            <input
                                type="text"
                                className={`input-activo ${errRepresentantesLegales[i].relacion ? 'input-error' : ''}`}
                                value={representantesLegales[i].relacion}
                                onChange={(e) =>
                                    dispatch(
                                        changeRepresentanteLegalField({
                                            index: i,
                                            field: 'relacion',
                                            content: e.target.value,
                                        })
                                    )
                                }
                            />
                            {errRepresentantesLegales[i].relacion && (
                                <p className="error-text error-color">*Campo obligatorio</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="row">
                        <div className="col-12 cedula-title">
                            <span>Rut del representante legal</span>
                        </div>
                        <div className="col-lg-8 col-sm-6 cedula-instruccion">
                            <span>
                                Sube un imagen de tu Cédula de identidad por ambos lados. (Formato JPG, PNG ó PDF con un
                                máximo de 9MB)
                            </span>
                        </div>
                        <div className="col-lg-4 col-sm-6 cedula-button">
                            <div
                                className="btn btn-avante-secondary btn-home d-flex align-items-center justify-content-center"
                                onClick={() => setShowModalCedula(true)}
                            >
                                Subir Cédula
                                <ModalCedula show={showModalCedula} setShow={setShowModalCedula} index={i} />
                            </div>
                        </div>
                        {representantesLegales[i].rutRepresentanteLegalDorso.length !== 0 ||
                            (representantesLegales[i].rutRepresentanteLegalFrente.length !== 0 && (
                                <span className="col-12 cedula-sub-title">Documentos Adjuntos:</span>
                            ))}
                        <div className="col-lg-8 col-sm-8 cedula-button">
                            {representantesLegales[i].rutRepresentanteLegalFrente.map((el: any, index: number) => {
                                return (
                                    <div className="row">
                                        <div key={i} className="col-8 d-flex text-center ">
                                            <span className="mr-3">{el.path} </span>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                            <img
                                                style={{ width: '16px', cursor: 'pointer' }}
                                                src={iconClose}
                                                alt=""
                                                onClick={() =>
                                                    dispatch(
                                                        removeCedulaFrenteRepresentanteLegal({ index: i, i: index })
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                            {representantesLegales[i].rutRepresentanteLegalDorso.map((el: any, index: number) => {
                                return (
                                    <div className="row">
                                        <div key={i} className="col-8 d-flex text-center ">
                                            <span className="mr-3">{el.path} </span>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                            <img
                                                style={{ width: '16px', cursor: 'pointer' }}
                                                src={iconClose}
                                                alt=""
                                                onClick={() =>
                                                    dispatch(
                                                        removeCedulaDorsoRepresentanteLegal({ index: i, i: index })
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
