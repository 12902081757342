import React from 'react';
import './ARepresentanteLegalList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addRepresentanteLegal, changeRepresentanteLegalError } from '../../../redux/reducers/juridicaForm';
import { ARepresentanteLegalForm } from './ARepresentanteLegalForm/ARepresentanteLegalForm';

export const ARepresentanteLegalList = ({ setFormStep }: any) => {
    const dispatch = useDispatch();
    const representantesLegales = useSelector((state: any) => state.juridicaForm.representantesLegales);
    const validateEmail = (email: any) => {
        var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    const validateRepresentantesLegales = () => {
        let isValid = true;
        representantesLegales.map((item: any, i: any) => {
            Object.entries(item).map((key: any, keyI: any) => {
                if (key[0] !== 'dpto') {
                    dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: key[1] === '' }));
                    if (key[1] === '') isValid = false;
                }
                if (key[0] === 'email') {
                    if (!validateEmail(key[1])) {
                        dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: true }));
                        isValid = false;
                    } else {
                        dispatch(changeRepresentanteLegalError({ index: i, field: key[0], content: false }));
                    }
                }
                return null;
            });
            return null;
        });
        return isValid;
    };
    return (
        <>
            {representantesLegales.map((repLegal: any, i: number) => {
                return <ARepresentanteLegalForm i={i} />;
            })}
            <div className="row">
                <div className="col-12 mt-2 mb-2">
                    <div className="row d-flex justify-content-start">
                        <div className="col-lg-5 col-md-5 col-sm-5 mt-1">
                            <div
                                className="btn btn-primary btn-avante-secondary-white btn-home d-flex align-items-center justify-content-center"
                                onClick={() => dispatch(addRepresentanteLegal())}
                            >
                                Agregar otro representante
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5 mb-3">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                            <div
                                className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                onClick={() => {
                                    setFormStep(0);
                                }}
                            >
                                Anterior
                            </div>
                        </div>
                        <div className="col"></div>
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-1">
                            <div
                                className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                onClick={() => {
                                    if (validateRepresentantesLegales()) {
                                        setFormStep(2);
                                    }
                                }}
                            >
                                Continuar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
