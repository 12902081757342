import React, { useState } from 'react';
import { ModalRepresentanteLegal } from '../ModalRepresentanteLegal/ModalRepresentanteLegal';
import iconClose from '../../assets/svg/icon-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeRepresentanteLegalField,
    removeDocumentosRepresentaneLegal,
    removeRepresentanteLegal,
} from '../../redux/reducers/naturalForm';

export const RepresentanteLegalForm = ({ index }: any) => {
    const [showRepresentanteLegalModal, setShowRepresentanteLegalModal] = useState(false);
    const representantesLegales = useSelector((state: any) => state.naturalForm.representantesLegales);
    const errRepresentantesLegales = useSelector((state: any) => state.naturalForm.errRepresentantesLegales[index]);
    const dispatch = useDispatch();

    return (
        <>
            {index !== 0 ? <div className={`row sparation-bar mt-4 mb-4`}></div> : ''}

            {index !== 0 && index === representantesLegales.length - 1 ? (
                <div className={`row`}>
                    <div className="col-12 pru ">
                        <img
                            src={iconClose}
                            onClick={() => {
                                dispatch(removeRepresentanteLegal({ index: index }));
                            }}
                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            alt="icon-close"
                        />
                    </div>
                </div>
            ) : (
                ''
            )}

            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-9 input-box">
                    <label>
                        {errRepresentantesLegales.rutPasaporte ? <span className="error-color">*</span> : ''}
                        Rut/Pasaporte
                    </label>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 input-box representante-legal-input">
                    <input
                        className={`input-activo ${errRepresentantesLegales.rutPasaporte ? 'input-error' : ''}`}
                        type="text"
                        value={representantesLegales[index].rutPasaporte}
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: index,
                                    field: 'rutPasaporte',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errRepresentantesLegales.rutPasaporte && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.nombres ? <span className="error-color">*</span> : ''}
                        Nombres
                    </label>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 input-box representante-legal-input">
                    <input
                        className={`input-activo ${errRepresentantesLegales.nombres ? 'input-error' : ''}`}
                        type="text"
                        value={representantesLegales[index].nombres}
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: index,
                                    field: 'nombres',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errRepresentantesLegales.nombres && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.apellidoPaterno ? <span className="error-color">*</span> : ''}
                        Apellido Paterno
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box representante-legal-input">
                    <input
                        className={`input-activo ${errRepresentantesLegales.apellidoPaterno ? 'input-error' : ''}`}
                        value={representantesLegales[index].apellidoPaterno}
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: index,
                                    field: 'apellidoPaterno',
                                    content: e.target.value,
                                })
                            );
                        }}
                        placeholder="Apellido"
                        type="text"
                    />
                    {errRepresentantesLegales.apellidoPaterno && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.apellidoMaterno ? <span className="error-color">*</span> : ''}
                        Apellido Materno
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 input-box representante-legal-input">
                    <input
                        className={`input-activo ${errRepresentantesLegales.apellidoMaterno ? 'input-error' : ''}`}
                        value={representantesLegales[index].apellidoMaterno}
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: index,
                                    field: 'apellidoMaterno',
                                    content: e.target.value,
                                })
                            );
                        }}
                        placeholder="Apellido"
                        type="text"
                    />
                    {errRepresentantesLegales.apellidoMaterno && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-12 input-box">
                    <label htmlFor="">Relación</label>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-around align-items-left mt-2 flex-column flex-lg-row">
                            <span className="input-radio-box-representante d-flex align-items-center ">
                                <input
                                    checked={representantesLegales[index].relacion === 'Mandatario'}
                                    className="input-radio"
                                    type="radio"
                                    onChange={() =>
                                        dispatch(
                                            changeRepresentanteLegalField({
                                                index: index,
                                                field: 'relacion',
                                                content: 'Mandatario',
                                            })
                                        )
                                    }
                                />
                                Mandatario
                            </span>
                            <span className="input-radio-box-representante d-flex align-items-center">
                                <input
                                    checked={representantesLegales[index].relacion === 'Padre o Madre'}
                                    className="input-radio"
                                    type="radio"
                                    onChange={() =>
                                        dispatch(
                                            changeRepresentanteLegalField({
                                                index: index,
                                                field: 'relacion',
                                                content: 'Padre o Madre',
                                            })
                                        )
                                    }
                                />
                                Padre o Madre
                            </span>
                            <span className="input-radio-box-representante d-flex align-items-center">
                                <input
                                    checked={representantesLegales[index].relacion === 'Otro'}
                                    className="input-radio"
                                    type="radio"
                                    onChange={() =>
                                        dispatch(
                                            changeRepresentanteLegalField({
                                                index: index,
                                                field: 'relacion',
                                                content: 'Otro',
                                            })
                                        )
                                    }
                                />
                                Otro
                            </span>
                            <div className="input-box">
                                <label>¿Cuál?</label>
                            </div>
                            <div
                                className={`d-flex justify-content-center align-items-center representante-legal-input`}
                            >
                                <input
                                    disabled={representantesLegales[index].relacion !== 'Otro'}
                                    className="input-activo"
                                    placeholder=""
                                    type="text"
                                    // value={representantesLegales[index].relacion}
                                    onChange={(e) => {
                                        dispatch(
                                            changeRepresentanteLegalField({
                                                index: index,
                                                field: 'otro',
                                                content: e.target.value,
                                            })
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.telefono ? <span className="error-color">*</span> : ''}
                        Teléfono
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box representante-legal-input">
                    <input
                        className={`input-activo ${errRepresentantesLegales.telefono ? 'input-error' : ''}`}
                        placeholder="+56 9 7485120-5"
                        value={representantesLegales[index].telefono}
                        type="text"
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: index,
                                    field: 'telefono',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errRepresentantesLegales.telefono && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.email ? <span className="error-color">*</span> : ''}
                        Email
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box representante-legal-input">
                    <input
                        className={`input-activo ${errRepresentantesLegales.email ? 'input-error' : ''}`}
                        placeholder="email@gmail.com"
                        value={representantesLegales[index].email}
                        type="text"
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({ index: index, field: 'email', content: e.target.value })
                            );
                        }}
                    />
                    {errRepresentantesLegales.email && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
            </div>
            {/* <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.rutRepresentanteLegal ? <span className="error-color">*</span> : ''}
                        Rut del representante legal
                    </label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-6 input-box representante-legal-input">
                    <input
                        className={`input-activo ${
                            errRepresentantesLegales.rutRepresentanteLegal ? 'input-error' : ''
                        }`}
                        placeholder="rut del representante"
                        value={representantesLegales[index].rutRepresentanteLegal}
                        type="text"
                        onChange={(e) => {
                            dispatch(
                                changeRepresentanteLegalField({
                                    index: index,
                                    field: 'rutRepresentanteLegal',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errRepresentantesLegales.rutRepresentanteLegal && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
            </div> */}
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-6 input-box">
                    <label>
                        {errRepresentantesLegales.documentos ? <span className="error-color">*</span> : '*'}Adjuntar:
                        Los documentos que acrediten la representación y/o mandato
                    </label>
                    {errRepresentantesLegales.documentos && (
                        <p className="error-text error-color mb-3">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <div
                        className="btn btn-avante-secondary btn-home d-flex align-items-center justify-content-center"
                        onClick={() => setShowRepresentanteLegalModal(true)}
                    >
                        <ModalRepresentanteLegal
                            show={showRepresentanteLegalModal}
                            setShow={setShowRepresentanteLegalModal}
                            index={index}
                        />
                        Adjuntar
                    </div>
                </div>
            </div>
            {representantesLegales[index].documentos.length !== 0 && (
                <span className="col-12 cedula-sub-title">Documentos Adjuntos:</span>
            )}
            {representantesLegales[index].documentos.map((el: any, i: number) => {
                return (
                    <div className="row" key={i}>
                        <div key={i} className="col-8 d-flex text-center ">
                            <span className="mr-3">{el.path} </span>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <img
                                style={{ width: '16px', cursor: 'pointer' }}
                                src={iconClose}
                                alt=""
                                onClick={() =>
                                    dispatch(removeDocumentosRepresentaneLegal({ repIndex: index, docIndex: i }))
                                }
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};
