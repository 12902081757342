import React from 'react';
//import { ciudade } from '../../utils/ciudades';
//import { comuna } from '../../utils/comunas';

import { useDispatch, useSelector } from 'react-redux';
import { changeAgenteField } from '../../redux/reducers/naturalForm';

export const FormularioAgente = ({ index }: any) => {
    const agenteData = useSelector((state: any) => state.naturalForm.agente);
    const errAgenteData = useSelector((state: any) => state.naturalForm.errAgente);
    const dispatch = useDispatch();

    return (
        <>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.nombreRazonSocial ? <span className="error-color">*</span> : ''}
                        Nombre o razón social
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.nombreRazonSocial ? 'input-error' : ''}`}
                        type="text"
                        placeholder="Nombre o razón social"
                        value={agenteData.nombreRazonSocial}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    field: 'nombreRazonSocial',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errAgenteData.nombreRazonSocial && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.rolUnicoTributario ? <span className="error-color">*</span> : ''}
                        Rol único tributario
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.rolUnicoTributario ? 'input-error' : ''}`}
                        type="text"
                        placeholder="Rol único tributario"
                        value={agenteData.rolUnicoTributario}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    index: index,
                                    field: 'rolUnicoTributario',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errAgenteData.rolUnicoTributario && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.nombreCompletoRepresentante ? <span className="error-color">*</span> : ''}
                        Nombre completo representante
                    </label>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.nombreCompletoRepresentante ? 'input-error' : ''}`}
                        type="text"
                        placeholder="Nombre completo representante"
                        value={agenteData.nombreCompletoRepresentante}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    index: index,
                                    field: 'nombreCompletoRepresentante',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errAgenteData.nombreCompletoRepresentante && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.rolUnicoTributarioRepresentante ? <span className="error-color">*</span> : ''}
                        Rut representante
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.rolUnicoTributarioRepresentante ? 'input-error' : ''}`}
                        type="text"
                        placeholder="Rut representante"
                        value={agenteData.rolUnicoTributarioRepresentante}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    index: index,
                                    field: 'rolUnicoTributarioRepresentante',
                                    content: e.target.value,
                                })
                            );
                        }}
                    />
                    {errAgenteData.rolUnicoTributarioRepresentante && (
                        <p className="error-text error-color">*Campo obligatorio</p>
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.direccion ? <span className="error-color">*</span> : ''}
                        Dirección
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.direccion ? 'input-error' : ''}`}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    index: index,
                                    field: 'direccion',
                                    content: e.target.value,
                                })
                            );
                        }}
                        value={agenteData.direccion}
                        placeholder="Dirección"
                        type="text"
                    />
                    {errAgenteData.direccion && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>

                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.comuna ? <span className="error-color">*</span> : ''}
                        Comuna
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errAgenteData.comuna ? 'input-error' : ''}`}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    index: index,
                                    field: 'comuna',
                                    content: e.target.value,
                                })
                            );
                        }}
                        value={agenteData.comuna}
                        placeholder="Comuna"
                        type="text"
                    />
                    {errAgenteData.comuna && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>

                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.ciudad ? <span className="error-color">*</span> : ''}
                        Ciudad
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.ciudad ? 'input-error' : ''}`}
                        onChange={(e) => {
                            dispatch(
                                changeAgenteField({
                                    index: index,
                                    field: 'ciudad',
                                    content: e.target.value,
                                })
                            );
                        }}
                        value={agenteData.ciudad}
                        placeholder="Ciudad "
                        type="text"
                    />
                    {errAgenteData.ciudad && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 input-box">
                    <label>
                        {errAgenteData.telefono ? <span className="error-color">*</span> : ''}
                        Teléfono
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box ">
                    <input
                        className={`input-activo ${errAgenteData.telefono ? 'input-error' : ''}`}
                        placeholder="+52 317 100 9800"
                        type="text"
                        value={agenteData.telefono}
                        onChange={(e) => {
                            dispatch(changeAgenteField({ index: index, field: 'telefono', content: e.target.value }));
                        }}
                    />
                    {errAgenteData.telefono && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-9  input-box">
                    <label>
                        {errAgenteData.email ? <span className="error-color">*</span> : ''}
                        Correo electrónico
                    </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 input-box">
                    <input
                        className={`input-activo ${errAgenteData.email ? 'input-error' : ''}`}
                        placeholder="email@gmail.com"
                        type="text"
                        value={agenteData.email}
                        onChange={(e) => {
                            dispatch(changeAgenteField({ index: index, field: 'email', content: e.target.value }));
                        }}
                    />
                    {errAgenteData.email && <p className="error-text error-color">*Campo obligatorio</p>}
                </div>
            </div>
        </>
    );
};
